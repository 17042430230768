import React from 'react';
import { Row, Col } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Helmet from 'react-helmet';
import { links } from 'constants/external-links';
import Link from 'components/common/link';
import { Loader } from 'components/common/loader';
import usePageTracking from 'hooks/use-page-tracking';
import styled from 'types/theme.types';

type LoginLayoutProps = {
  pageTitleId: string;
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
};

const LoginLayout: React.FunctionComponent<LoginLayoutProps> = ({ pageTitleId, leftContent, rightContent }) => {
  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: pageTitleId });
  usePageTracking(pageTitle);

  return (
    <Row style={{ height: '100vh' }}>
      <Helmet>
        <title>{pageTitle} | env0</title>
      </Helmet>
      <LeftCol sm={24} md={12}>
        {leftContent}
      </LeftCol>
      <RightCol sm={24} md={12}>
        {rightContent}
        <LegalContainer>
          <FormattedMessage
            id="legal.signup"
            values={{
              terms: <Link messageId="legal.terms" url={links.legal.TERMS} />,
              privacy: <Link messageId="legal.privacy" url={links.legal.PRIVACY} />
            }}
          />
        </LegalContainer>
      </RightCol>
    </Row>
  );
};

const LoaderOverlay = () => (
  <LoaderWrapper>
    <Loader />
  </LoaderWrapper>
);

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
`;

const LeftCol = styled(Col)`
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.sideMenuBackground};
`;

const RightCol = styled(Col)`
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => (theme.mode === 'dark' ? theme.primaryGray : theme.primaryWhite)};
`;

const LegalContainer = styled.div`
  width: 95%;
  text-align: center;
  margin-top: auto;
  margin-bottom: 5px;
`;

export default LoginLayout;
export { LoaderOverlay };
