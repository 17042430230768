import React from 'react';
import { MoonFilled, SunFilled } from '@ant-design/icons';
import Tooltip from 'components/common/tooltip';
import useStores from 'hooks/use-stores.hooks';
import { Button } from 'antd';

export default function AppThemeSwitchButton() {
  const {
    userStore: { appTheme, setAppTheme }
  } = useStores();

  return (
    <Tooltip placement="left" titleId="toggle.theme">
      <Button type="text" onClick={() => setAppTheme(appTheme === 'dark' ? 'light' : 'dark')}>
        {appTheme === 'dark' ? <MoonFilled /> : <SunFilled />}
      </Button>
    </Tooltip>
  );
}
