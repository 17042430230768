import React from 'react';
import ErrorContainer from 'components/common/error-container';

interface Props {
  reason?: string;
}
const ForbiddenRoute: React.FunctionComponent<Props> = ({ reason = 'unknown' }) => {
  return <ErrorContainer message="forbidden.route" errorExtraInfo={{ reason }} />;
};

export default ForbiddenRoute;
