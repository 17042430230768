import get from 'lodash/fp/get';
import type { User, DeploymentLog, OrganizationUser } from 'types/api.types';
import BotPlaceholder from 'assets/images/bot.svg';
import UserIcon from 'assets/images/user-icon-two-tone.svg';
import dayjs from 'types/dayjs.types';
import type { Auth0UserProfile } from 'auth0-js';
import { SYSTEM_OWNER, ENV0_AVATAR } from 'constants/api.constants';
import { intl } from 'components/localization';
import { isNil } from 'lodash/fp';
import type { UserRoleAssignmentRow } from 'components/common/role-assignments/types';
import type { EnvironmentApi } from '@env0/environment-service/api';

type UserName = Pick<User, 'name' | 'given_name' | 'family_name' | 'email'>;
type UserImage = Pick<User, 'picture' | 'app_metadata'>;

const enterpriseConnectionStrategy = ['samlp', 'waad'];

function isAnEnv0Deployment(deploymentLog: DeploymentLog) {
  const triggerNamesByEnv0: Array<EnvironmentApi.DeploymentLog['triggerName']> = [
    'workflow',
    'ttl',
    'driftDetection',
    'scheduled',
    'driftRemediation'
  ];

  return deploymentLog.triggerName && triggerNamesByEnv0.includes(deploymentLog.triggerName);
}

export const getDeployerUserName = (deploymentLog: DeploymentLog): string => {
  const translatedTriggerName = intl.formatMessage({ id: `deployment.trigger-name.${deploymentLog.triggerName}` });

  if (deploymentLog.startedBy === SYSTEM_OWNER && isAnEnv0Deployment(deploymentLog))
    // user and prPlan are showing name and vcs link, respectively
    return translatedTriggerName;

  const userName = deploymentLog.gitUser || getUserName(deploymentLog.startedByUser);

  // This check is intended to fix an issue with older deployments that were created without a triggerName
  if (isNil(deploymentLog.triggerName)) return userName;

  return `${userName} (${translatedTriggerName})`;
};

export const getUserName = (profile?: UserName | null): string => {
  if (profile?.given_name && profile?.family_name) {
    return `${profile.given_name} ${profile.family_name}`;
  }
  return get('name', profile) || get('email', profile) || '';
};

export const getDeployerUserImageUrl = (deploymentLog: DeploymentLog) => {
  if (isAnEnv0Deployment(deploymentLog)) return ENV0_AVATAR;

  return deploymentLog.gitAvatarUrl || getUserImageUrl({ user: deploymentLog.startedByUser as User });
};

export const getUserImageUrl = ({ user }: { user?: UserImage | null }) => {
  if (!user || !user.picture) {
    return UserIcon;
  } else if (user.app_metadata && user.app_metadata.isApiKey) {
    return BotPlaceholder;
  }

  return user.picture;
};

export const compareUsersByName = (user1: UserName, user2: UserName) => {
  return getUserName(user1).localeCompare(getUserName(user2));
};

export const compareUsersByEmail = (user1: OrganizationUser, user2: OrganizationUser) => {
  return user1.user.email.localeCompare(user2.user.email);
};

export const compareUsersByStatus = (user1: OrganizationUser, user2: OrganizationUser) => {
  return user1.status.localeCompare(user2.status);
};

export const compareUsersByCreatedDate = (user1: OrganizationUser, user2: OrganizationUser) => {
  return dayjs(user1.user.created_at).valueOf() - dayjs(user2.user.created_at).valueOf();
};

export const compareUsersByLastLoginTime = (user1: OrganizationUser, user2: OrganizationUser) => {
  return dayjs(user1.user.last_login).valueOf() - dayjs(user2.user.last_login).valueOf();
};

export const isSAMLUser = (profile: Auth0UserProfile | undefined): boolean => {
  return enterpriseConnectionStrategy.some(enterprise => profile?.sub?.startsWith(enterprise));
};

export const compareUsersByAssignment = (user1: UserRoleAssignmentRow, user2: UserRoleAssignmentRow) => {
  const user1priority = user1.isCurrentUser ? 3 : user1.isOrganizationAdmin ? 2 : user1.initialData.assigned ? 1 : 0;
  const user2priority = user2.isCurrentUser ? 3 : user2.isOrganizationAdmin ? 2 : user2.initialData.assigned ? 1 : 0;

  return user2priority - user1priority;
};
