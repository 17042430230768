import styled from 'types/theme.types';

const inlineContainer = `
  display: inline-block;
  width: max-content;
  float: right;
`;

const blockContainer = `
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const FormButtonsContainer = styled.div<{
  withSeparator?: boolean;
  isInline?: boolean;
}>`
  ${({ isInline }) => (isInline ? inlineContainer : blockContainer)};
  align-items: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: ${({ theme, withSeparator }) => withSeparator && '1px solid ' + theme.lightGray};

  button,
  a {
    width: 210px;
  }

  a {
    border-color: ${({ theme }) => theme.primaryBlue};
    margin-left: 20px;
    color: ${({ theme }) => theme.primaryBlue};

    &:hover {
      background-color: ${({ theme }) => theme.primaryBlue};
      color: ${({ theme }) => theme.primaryWhite};
    }
  }

  button {
    text-transform: uppercase;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }
`;

export default FormButtonsContainer;
