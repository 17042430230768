import { promiseWithTimeout } from 'utils/promise-timeout-utils';
import * as Sentry from '@sentry/react';

/// <reference types="@types/segment-analytics" />

const SEGMENT_READY_TIMEOUT = 1000;

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

export interface TrackingData {
  eventName: string;
  eventProperties?: any;
}

export enum EventNames {
  VCS_INTEGRATION_ATTEMPT = 'VCS_INTEGRATION_ATTEMPT',
  VCS_INTEGRATION_FAILURE = 'VCS_INTEGRATION_FAILURE',
  VCS_INTEGRATION_SUCCESS = 'VCS_INTEGRATION_SUCCESS',
  TOP_BAR_SCHEDULE_DEMO = 'TOP_BAR_SCHEDULE_DEMO',
  TOP_BAR_DOCS_CLICK = 'TOP_BAR_DOCS_CLICK',
  MOBILE_BLOCKER = 'MOBILE_BLOCKER',
  SIGNUP = 'SIGN_UP_CLIENT_SIDE',
  SSO_ATTEMPT = 'SSO_ATTEMPT',
  TEMPLATE_WIZARD_SETTINGS_STEP = 'TEMPLATE_WIZARD_SETTINGS_STEP',
  TEMPLATE_WIZARD_VCS_STEP = 'TEMPLATE_WIZARD_VCS_STEP',
  TEMPLATE_WIZARD_CREDENTIALS_STEP = 'TEMPLATE_WIZARD_CREDENTIALS_STEP',
  TEMPLATE_WIZARD_VARIABLES_STEP = 'TEMPLATE_WIZARD_VARIABLES_STEP',
  TEMPLATE_WIZARD_PROJECTS_STEP = 'TEMPLATE_WIZARD_PROJECTS_STEP',
  TEMPLATE_WIZARD_MAPPING_STEP = 'TEMPLATE_WIZARD_MAPPING_STEP',
  TEMPLATE_WIZARD_ENVIRONMENT_DETAILS_STEP = 'TEMPLATE_WIZARD_ENVIRONMENT_DETAILS_STEP',
  TEMPLATE_WIZARD_DONE_STEP = 'TEMPLATE_WIZARD_DONE_STEP',
  TEMPLATE_WIZARD_SUBMIT = 'TEMPLATE_WIZARD_SUBMIT',
  SINGLE_USE_TEMPLATE_WIZARD_SUBMIT = 'SINGLE_USE_TEMPLATE_WIZARD_SUBMIT',
  CREATE_NEW_ENVIRONMENT_WITH_TEMPLATE_CLICK = 'CREATE_NEW_ENVIRONMENT_WITH_TEMPLATE_CLICK',
  CREATE_NEW_ENVIRONMENT_WITHOUT_TEMPLATE_CLICK = 'CREATE_NEW_ENVIRONMENT_WITHOUT_TEMPLATE_CLICK',
  ERROR_REPORTED = 'ERROR_REPORTED',
  CREATED_ENVIRONMENT = 'CREATED_ENVIRONMENT_CLIENT_SIDE',
  DEPLOYED_ENVIRONMENT = 'DEPLOYED_ENVIRONMENT_CLIENT_SIDE',
  PRETTY_PLAN_PRETTY_CLICK = 'PRETTY_PLAN_PRETTY_CLICK',
  PRETTY_PLAN_TEXT_CLICK = 'PRETTY_PLAN_TEXT_CLICK',
  PRETTY_PLAN_SEARCH_BAR_CLICK = 'PRETTY_PLAN_SEARCH_BAR_CLICK',
  PRETTY_PLAN_CHANGE_BLOCK_VIEW_CLICK = 'PRETTY_PLAN_RESOURCE_VIEW_CLICK',
  IN_APP_WHATS_NEW_SHOW_NEW_ITEMS = 'IN_APP_WHATS_NEW_SHOW_NEW_ITEMS',
  IN_APP_WHATS_NEW_ICON_CLICKED = 'IN_APP_WHATS_NEW_ICON_CLICKED',
  IN_APP_WHATS_NEW_VIEW_ITEM_CLICKED = 'IN_APP_WHATS_NEW_VIEW_ITEM_CLICKED',
  IN_APP_WHATS_NEW_VIEW_ALL_CLICKED = 'IN_APP_WHATS_NEW_VIEW_ALL_CLICKED',
  IN_APP_WHATS_NEW_RSS_FEED_CLICKED = 'IN_APP_WHATS_NEW_RSS_FEED_CLICKED',
  CMD_K_ITEM_CLICKED = 'CMD_K_ITEM_CLICKED',
  CMD_K_OPENED = 'CMD_K_OPENED',
  ORGANIZATION_CREATED = 'ORGANIZATION_CREATED',
  ONBOARDING_STARTED = 'ONBOARDING_STARTED',
  ONBOARDING_COMPLETED = 'ONBOARDING_COMPLETED',
  ONBOARDING_EXIT_ON_STEP = 'ONBOARDING_EXIT_ON_STEP',
  ONBOARDING_CREDENTIALS_COMPLETED = `ONBOARDING_CREDENTIALS_COMPLETED`,
  ONBOARDING_CREDENTIALS_SKIPPED = `ONBOARDING_CREDENTIALS_SKIPPED`,
  ONBOARDING_ENV_CREATED = `ONBOARDING_ENV_CREATED`, // TODO: https://trello.com/c/9KnZrxxF/6254-create-vs-import-environment
  ONBOARDING_ENV_IMPORTED = `ONBOARDING_ENV_IMPORTED`,
  ALL_QUEUED_DEPLOYMENTS_CANCELED = `ALL_QUEUED_DEPLOYMENTS_CANCELED`,
  CREDENTIAL_UPDATED = 'CREDENTIAL_UPDATED',
  TIER_LIMIT_REACHED_FE = 'TIER_LIMIT_REACHED_FE',
  GAME_START = 'GAME_START',
  STATE_VERSION_DOWNLOAD = 'STATE_VERSION_DOWNLOAD',
  STATE_VERSION_OPEN_DEPLOYMENT_LOG = 'STATE_VERSION_OPEN_DEPLOYMENT_LOG',
  ENVIRONMENT_LOCK_UPDATED = 'ENVIRONMENT_LOCK_UPDATED',
  PRO_TIER_MANAGE_SUBSCRIPTION = 'PRO_TIER_MANAGE_SUBSCRIPTION',
  BUSINESS_TIER_MANAGE_SUBSCRIPTION = 'BUSINESS_TIER_MANAGE_SUBSCRIPTION',
  CONTACT_US_BASIC_TIER_CLICK = 'CONTACT_US_BASIC_TIER_CLICK',
  CONTACT_US_BUSINESS_TIER_CLICK = 'CONTACT_US_BUSINESS_TIER_CLICK',
  CONTACT_US_PAY_GATE_CLICK = 'CONTACT_US_PAY_GATE_CLICK',
  UPGRADE_TO_PRO_TIER_CLICK = 'UPGRADE_TO_PRO_TIER_CLICK',
  SAVE_AS_TEMPLATE = 'SAVE_AS_TEMPLATE',
  RUN_MASS_IMPORT = 'RUN_MASS_IMPORT',
  HOMEPAGE_NAVIGATE_FROM_INSIGHTS = 'HOMEPAGE_NAVIGATE_FROM_INSIGHTS',
  HOMEPAGE_NAVIGATE_FROM_RECENT_TO_ALL_PROJECT = 'HOMEPAGE_NAVIGATE_FROM_RECENT_TO_ALL_PROJECT',
  HOMEPAGE_NAVIGATE_FROM_RECENT_TO_SPECIFIC_PROJECT = 'HOMEPAGE_NAVIGATE_FROM_RECENT_TO_SPECIFIC_PROJECT'
}

const waitForLoad = () => {
  if (!window.analytics) throw new Error('analytics not loaded');
  const promiseForReady = new Promise<void>(resolve => window.analytics.ready(resolve));
  // if an ad blocker is used, analytics.ready never returns
  return promiseWithTimeout(promiseForReady, SEGMENT_READY_TIMEOUT);
};

export const load = (key: string) => {
  window.analytics?.load(key);
};

export const page = (name?: string, properties?: Record<string, any>) => {
  window.analytics?.page(name, properties);
};

export const track = (name: string, properties?: any) => {
  window.analytics?.track(name, properties);
};

export const identify = (id: string, profile: any) => {
  window.analytics?.identify(id, profile);
  Sentry.setUser({ id, ...profile });
};

export const group = (groupId: string, groupInfo: any) => {
  window.analytics?.group(groupId, groupInfo);
};

export const getAnonymousId = async () => {
  try {
    await waitForLoad();
    return window.analytics.user().anonymousId();
  } catch {
    return '';
  }
};
