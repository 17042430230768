import { Dropdown, Input } from 'antd';
import type { InputProps, InputRef, MenuProps } from 'antd';
import withTooltip from 'components/common/form-controlled/with-tooltip.hoc';
import { getCommonInputCssFromProps } from 'components/common/input-components/common-styles';
import { ErrorMessage } from 'components/common/form-components';
import React from 'react';
import isString from 'lodash/isString';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'types/theme.types';
import withLabel from 'components/common/form-controlled/with-label.hoc';

export interface TextInputProps extends InputProps {
  id?: string;
  name?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  placeholderValues?: Record<string, string>;
  disabled?: boolean;
  invalid?: boolean;
  error?: InputError;
  hideError?: boolean;
  errorShouldNotTakeSpace?: boolean;
  'data-e2e': string;
  fullWidth?: boolean;
  grow?: boolean;
  menu?: MenuProps;
  addonColor?: string;
  ref?: React.Ref<InputRef>;
}

export type InputError = string | InputErrorExtended | null;
type InputErrorExtended = { id: string; values: Record<string, any> };

const TextInput: React.FunctionComponent<TextInputProps> = React.forwardRef<InputRef, TextInputProps>((props, ref) => {
  const {
    placeholder,
    placeholderValues,
    error,
    hideError,
    fullWidth,
    grow,
    menu,
    errorShouldNotTakeSpace,
    ...otherProps
  } = props;
  const intl = useIntl();
  const translatedPlaceholder = placeholder && intl.formatMessage({ id: placeholder }, placeholderValues);

  const errorAsObj = error as InputErrorExtended;
  const errorId = !isString(error) ? errorAsObj?.id : error;
  const errorValues = errorAsObj?.values;

  const input = (
    <StyledInput
      {...otherProps}
      ref={ref}
      placeholder={translatedPlaceholder}
      autoComplete={menu ? 'off' : undefined}
    />
  );

  return (
    <FlexColumn fullWidth={fullWidth} grow={grow}>
      {menu ? (
        <Dropdown disabled={otherProps.disabled} menu={menu}>
          <span>{input}</span>
        </Dropdown>
      ) : (
        input
      )}
      {!hideError && (
        <ErrorMessage
          data-e2e={otherProps['data-e2e'] ? `${otherProps['data-e2e']}-error` : null}
          errorShouldNotTakeSpace={errorShouldNotTakeSpace}>
          {errorId && <FormattedMessage id={errorId} values={errorValues} />}
        </ErrorMessage>
      )}
    </FlexColumn>
  );
});

TextInput.displayName = 'TextInput';

const StyledInput = styled(Input)<TextInputProps>`
  ${({ invalid, error, theme }) => getCommonInputCssFromProps({ theme, invalid: invalid || Boolean(error) })}
  --size: 28px;
  color: ${({ theme }) => theme.primaryBlack} !important;
  height: var(--size) !important;
  line-height: var(--size) !important;

  /* the section below is when the text input uses antd addons */
  &.ant-input-group-wrapper {
    border: none;

    /* this is for the main input */
    .ant-input {
      ${({ invalid, error, theme }) =>
        getCommonInputCssFromProps({ theme, invalid: invalid || Boolean(error), excludeBorderRadius: true })}
      border-left: none;
      border-right: none;
    }

    /* this is for the addons themselves */
    .ant-input-group-addon {
      background: none;
      ${({ invalid, error, theme }) =>
        getCommonInputCssFromProps({ theme, invalid: invalid || Boolean(error), excludeBorderRadius: true })}
      ${({ addonColor }) => (addonColor ? `color: ${addonColor} !important;` : '')}
    }
  }
`;

const FlexColumn = styled.div<{ fullWidth?: boolean; grow?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}
  ${({ grow }) => (grow ? 'flex-grow: 1' : '')}
`;

export const TextInputWithLabel = withLabel(TextInput);
export const TextInputWithTooltipAndLabel = withLabel(withTooltip(TextInput));
export default TextInput;
