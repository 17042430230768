import React from 'react';
import { links } from 'constants/external-links';
import Link from 'components/common/link';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import ControlledDropdownInput from 'components/common/form-controlled/controlled-select';
import { SelectOption } from 'components/common/input-components/select';
import type { CredentialsInputProps } from 'components/organizations/credentials/credential-modal';
import ControlledCredentialTextInput from 'components/common/form-controlled/controlled-credential-text-input';

export const ociRegions = [
  'ap-sydney-1',
  'ap-melbourne-1',
  'sa-saopaulo-1',
  'sa-vinhedo-1',
  'ca-montreal-1',
  'ca-toronto-1',
  'sa-santiago-1',
  'sa-valparaiso-1',
  'sa-bogota-1',
  'eu-paris-1',
  'eu-marseille-1',
  'eu-frankfurt-1',
  'ap-hyderabad-1',
  'ap-mumbai-1',
  'il-jerusalem-1',
  'eu-milan-1',
  'ap-osaka-1',
  'ap-tokyo-1',
  'mx-queretaro-1',
  'mx-monterrey-1',
  'eu-amsterdam-1',
  'me-riyadh-1',
  'me-jeddah-1',
  'eu-jovanovac-1',
  'ap-singapore-1',
  'ap-singapore-2',
  'af-johannesburg-1',
  'ap-seoul-1',
  'ap-chuncheon-1',
  'eu-madrid-1',
  'eu-stockholm-1',
  'eu-zurich-1',
  'me-abudhabi-1',
  'me-dubai-1',
  'uk-london-1',
  'uk-cardiff-1',
  'us-ashburn-1',
  'us-chicago-1',
  'us-phoenix-1',
  'us-sanjose-1'
];

const prefix = 'settings.credentials.oci';
const docsLink = links.docs.DEPLOYMENT_CREDENTIALS.OCI_API_KEY_DOCS;

const OciApiKeyInputs = ({ form, usageType }: CredentialsInputProps) => {
  if (usageType !== 'DEPLOYMENT') throw new Error('Oci Api Key is for deployment only');

  return (
    <>
      <Link messageId={`${prefix}.apiKey.explanation`} url={docsLink} />
      <ControlledTextInput label={{ id: `${prefix}.tenancyOcid` }} name="value.tenancyOcid" form={form} />
      <ControlledTextInput label={{ id: `${prefix}.userOcid` }} name="value.userOcid" form={form} />
      <ControlledDropdownInput label={{ id: `${prefix}.region` }} name="value.region" form={form} showArrow>
        {ociRegions.map(value => (
          <SelectOption key={value} value={value}>
            {value}
          </SelectOption>
        ))}
      </ControlledDropdownInput>
      <ControlledTextInput label={{ id: `${prefix}.fingerprint` }} name="value.fingerprint" form={form} />
      <ControlledCredentialTextInput
        isSensitive={true}
        label={{ id: `${prefix}.privateKey` }}
        name="value.privateKey"
        textArea={true}
        form={form}
        placeholder={'-----BEGIN RSA PRIVATE KEY-----'}
      />
    </>
  );
};

export default OciApiKeyInputs;
