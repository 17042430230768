import React, { type ReactNode, useMemo } from 'react';
import { App, ConfigProvider } from 'antd';
import { useIntl } from 'react-intl';
import type { Locale } from 'antd/lib/locale';
import antdLocaleUS from 'antd/lib/locale/en_US';
import { getAntdTheme } from 'constants/antd.constants';
import useStores from 'hooks/use-stores.hooks';
import { observer } from 'mobx-react';

/**
 * This component is used to wrap the entire application.
 * The ConfigProvider sets the base tokens and the locales (translations) for the antd components.
 * The App component is used to set the base styles for the application (i.e. root font-size, root color, etc.)
 */

const ANTD_THEME = {
  light: getAntdTheme('light'),
  dark: getAntdTheme('dark')
};

const AntdWrapper: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const intl = useIntl();
  const {
    userStore: { appTheme }
  } = useStores();

  const theme = useMemo(() => ANTD_THEME[appTheme] || ANTD_THEME.light, [appTheme]);

  const locale = useMemo<Locale>(
    () => ({
      ...antdLocaleUS,
      Table: {
        ...antdLocaleUS.Table,
        triggerAsc: intl.formatMessage({ id: 'sort.ascending' }),
        triggerDesc: intl.formatMessage({ id: 'sort.descending' }),
        cancelSort: intl.formatMessage({ id: 'sort.cancel' })
      }
    }),
    [intl]
  );
  return (
    <ConfigProvider theme={theme} locale={locale}>
      <App>{children}</App>
    </ConfigProvider>
  );
};

export default observer(AntdWrapper);
