import { type BlueprintApi } from '@env0/blueprint-service/api';
import type { OrganizationApi } from '@env0/organization-service/api';
import Divider from 'components/common/divider';
import GitOnPremWebhookFields from 'components/templates/templates-wizard/vcs/git-on-prem-webhook.fields';
import { links } from 'constants/external-links';
import { bitbucketServerWebhookUrl, gitHubEnterpriseWebhookUrl, gitLabEnterpriseWebhookUrl } from 'constants/config';
import { isSsh } from 'components/templates/templates-wizard/url-utils';
import React from 'react';
import type { GitHostedProviderTypes, GitProviderTypes, Tokens } from 'types/api.types';
import type { VCSForm } from 'components/templates/templates-wizard/common/template-wizard.types';
import SshKeysField from 'components/templates/templates-wizard/settings/ssh-keys-field';
import TokenSelectField from 'components/templates/templates-wizard/vcs/token-select-field';
import Link from 'components/common/link';

interface Props {
  gitProvider: GitProviderTypes;
  webhookSecret: string | null;
  form: VCSForm;
  tokens: Tokens;
}

const providerToWebhookTypeMapper: Record<GitHostedProviderTypes, OrganizationApi.GetWebhookSecret.WebhookType> = {
  BitBucketServer: 'bitbucket-server',
  GitLabEnterprise: 'gitlab-enterprise',
  GitHubEnterprise: 'github-enterprise'
};

export const getWebhookTypeByProvider = (
  provider: GitHostedProviderTypes
): OrganizationApi.GetWebhookSecret.WebhookType => {
  return providerToWebhookTypeMapper[provider];
};

interface GitOnPremWebhookSectionProps {
  gitProvider: BlueprintApi.SourceTypes;
  webhookSecret: string;
  isTwoRows?: boolean;
}

export const GitOnPremWebhookSection: React.FC<GitOnPremWebhookSectionProps> = ({
  gitProvider,
  webhookSecret,
  isTwoRows
}) => {
  const getWebhookProps = () => {
    switch (gitProvider) {
      case 'BitBucketServer':
        return {
          docsLink: links.docs.TEMPLATES.BITBUCKET_SERVER_INTEGRATION,
          secretDocsLink: links.BITBUCKET_SERVER.WEBHOOK_SECRET_DOCS,
          webhookBaseUrl: bitbucketServerWebhookUrl,
          dataE2e: 'git-on-prem-webhook-fields-bit-bucket-server'
        };
      case 'GitLabEnterprise':
        return {
          docsLink: links.docs.TEMPLATES.GITLAB_EE_INTEGRATION,
          secretDocsLink: links.GITLAB_EE.WEBHOOK_SECRET_DOCS,
          webhookBaseUrl: gitLabEnterpriseWebhookUrl,
          dataE2e: 'git-on-prem-webhook-fields-git-lab-enterprise'
        };
      case 'GitHubEnterprise':
        return {
          docsLink: links.docs.TEMPLATES.GITHUB_ENTERPRISE_INTEGRATION,
          secretDocsLink: links.GITHUB_ENTERPRISE.WEBHOOK_SECRET_DOCS,
          webhookBaseUrl: gitHubEnterpriseWebhookUrl,
          dataE2e: 'git-on-prem-webhook-fields--git-hub-enterprise'
        };
    }
  };

  const webhookProps = getWebhookProps();
  if (!webhookProps) return null;

  if (gitProvider === 'BitBucketServer' || gitProvider === 'GitHubEnterprise' || gitProvider === 'GitLabEnterprise') {
    return (
      <GitOnPremWebhookFields
        docsLink={webhookProps.docsLink}
        gitProvider={gitProvider}
        secretDocsLink={webhookProps.secretDocsLink}
        webhookBaseUrl={webhookProps.webhookBaseUrl}
        data-e2e={webhookProps.dataE2e}
        webhookSecret={webhookSecret!}
        isTwoRows={isTwoRows}
      />
    );
  } else {
    return null;
  }
};

export const AuthSection: React.FC<Props> = ({ form, gitProvider, webhookSecret, tokens }) => {
  const repository = form.watch('repository');
  const SshKeysFieldLabel = <SshKeysField form={form} />;
  const GitTokenFieldLabel = (
    <>
      <TokenSelectField
        width={49}
        label={{ id: 'templates.add.new.field.token' }}
        data-e2e="token-select-field"
        form={form}
        gitProvider={gitProvider}
        info={{
          id: 'templates.add.new.information.token',
          values: {
            link: (text: string) => <Link plainText messageId={text} url={links.docs.TEMPLATES.TOKENS} />
          }
        }}
        tokens={tokens}
      />
    </>
  );

  return (
    <div data-e2e="authentication-section">
      <Divider data-e2e="authentication-divider" titleId="templates.add.new.divider.authentication" />
      <GitOnPremWebhookSection gitProvider={gitProvider} webhookSecret={webhookSecret!} />
      {gitProvider === 'Other' && (isSsh(repository) ? SshKeysFieldLabel : GitTokenFieldLabel)}
    </div>
  );
};
