import React from 'react';
import styled from 'types/theme.types';
import { useIntl } from 'react-intl';
import ErrorStateBotAnimation from 'assets/images/animations/env0-robot-state-error.json';
import Lottie from 'lottie-react';
import { reportError } from 'utils/sentry.utils';
import { useMount } from 'ahooks';
import isString from 'lodash/isString';

interface Props {
  errorExtraInfo?: Record<string, unknown> & { reason?: string };
  errorToReport?: any; // give this prop only if you want to report it to Sentry
  message?: string;
  messageValues?: Record<string, string>;
  icon?: React.ReactElement;
  className?: string;
}

const unexpectedErrorId = 'notifications.error.something.went.wrong';

const ErrorContainer: React.FunctionComponent<Props> = ({
  errorToReport,
  errorExtraInfo,
  message = unexpectedErrorId,
  messageValues,
  icon,
  className
}) => {
  const intl = useIntl();
  const translatedMessage = message && intl.formatMessage({ id: message, defaultMessage: message }, messageValues);

  useMount(() => {
    if (errorToReport) {
      reportError('ErrorContainer', errorToReport, {
        path: window.location.href,
        message: translatedMessage,
        ...errorExtraInfo
      });
    }
  });

  return (
    <Container data-e2e="error-container" className={className}>
      {icon || (
        <AnimationContainer
          animationData={ErrorStateBotAnimation}
          loop
          autoPlay
          data-e2e={message === unexpectedErrorId ? 'unexpected-error' : ''}
        />
      )}
      <EmptyLayoutMessage data-e2e="error-container-actual-msg">{translatedMessage}</EmptyLayoutMessage>
      {isString(errorExtraInfo?.reason) && <span>{errorExtraInfo!.reason}</span>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 46px);
  width: 100%;
  color: ${({ theme }) => theme.textGray};
`;

const EmptyLayoutMessage = styled.h2`
  font-size: 24px;
  white-space: pre-line;
`;

const AnimationContainer = styled(Lottie)`
  height: 300px;
`;

export default ErrorContainer;
