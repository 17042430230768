import React, { type ReactNode } from 'react';
import useStores from 'hooks/use-stores.hooks';
import { observer } from 'mobx-react';
import { ThemeProvider } from '@emotion/react';
import { THEME_VARIANTS } from 'constants/themes.constants';

const ThemeProviderWrapper: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const {
    userStore: { appTheme }
  } = useStores();

  return <ThemeProvider theme={THEME_VARIANTS[appTheme]}>{children}</ThemeProvider>;
};

export default observer(ThemeProviderWrapper);
