import styled from 'types/theme.types';

export const SwitcherContainer = styled.div`
  background: ${({ theme }) => theme.primaryWhite};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.lightGray};
  box-shadow: -1px 6px 14px 0 rgba(80, 80, 80, 0.5);
  font-size: 13px;
  line-height: 17px;
  overflow: hidden;
`;

export const SwitcherItem = styled.div<{ selected?: boolean }>`
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  color: ${({ selected, theme }) => (selected ? theme.primaryBlue : theme.primaryBlack)};
  ${({ selected, theme }) => (selected ? 'background: ' + theme.menuGrayLight + ';' : '')}

  &:hover {
    background: ${({ theme }) => theme.menuGray};
  }
`;

export const SwitcherRectangle = styled.div<{ selected?: boolean }>`
  height: 100%;
  width: 4px;
  margin-right: 5px;
  background: ${({ selected }) =>
    selected
      ? `linear-gradient(180deg, #00EDB9 0%, #7FE0D2 30.42%, #45E6F7 50.67%, #1AAEFC 71.51%, #8C85FF 100%)`
      : 'transparent'};
`;
