import BaseService from 'services/base-service';
import { action, observable } from 'mobx';
import type {
  ConfigurationProperty,
  ConfigurationPropertyError,
  ConfigurationType,
  ConfigurationValueType,
  VariablesPolicies
} from 'types/api.types';
import { ConfigurationScope } from 'types/api.types';
import cloneDeep from 'lodash/cloneDeep';
import {
  assignDefaultValues,
  getSchemaValue,
  hasConfigurationChanged,
  validateConfigurationProperty
} from 'utils/configuration.utils';
import { v4 as uuid } from 'uuid';

import type { ConfigurationPropertiesStore } from 'stores/mobx/configuration-properties.store';

export const SENSITIVE_MASK = '***************';

export class ConfigurationPropertyStore extends BaseService {
  readonly editScope: ConfigurationScope;
  readonly editScopeId: string;

  markToDelete: boolean = false;

  @observable
  isUpdated?: boolean = false;

  @observable
  isShow: boolean = true;

  @observable
  data: ConfigurationProperty;

  initialData: ConfigurationProperty;

  variablesPolicies: VariablesPolicies;

  id: string;

  @observable
  error?: ConfigurationPropertyError;

  constructor(
    private configurationPropertiesStore: ConfigurationPropertiesStore,
    data: Partial<ConfigurationProperty>
  ) {
    super(configurationPropertiesStore.service);
    this.id = data.id || uuid();
    this.editScope = configurationPropertiesStore.configurationsScope.scope!;
    this.editScopeId = configurationPropertiesStore.configurationsScope.scopeId!;
    this.initialData = assignDefaultValues(data) as ConfigurationProperty;
    this.data = cloneDeep(this.initialData) as ConfigurationProperty;
    this.variablesPolicies = configurationPropertiesStore.variablesPolicies;
  }

  @action
  propagatePropertyValue(data: Partial<ConfigurationProperty>) {
    this.data = { ...this.data, ...data };
    this.runValidation();
  }

  @action
  update(data: Partial<ConfigurationProperty>, runValidation = true) {
    this.isUpdated = true;

    if (this.isBelongUpperScope()) {
      this.data = {
        ...this.data,
        ...data,
        ...this.configurationPropertiesStore.configurationsScope,
        id: undefined,
        overwrites: {
          ...this.data
        } as any
      };

      this.markToDelete = false;
    } else {
      this.data = { ...this.data, ...data };
    }

    const isCurrentScopeVariableBackToOriginalValue =
      this.isBelongThisScope() && !this.isOverwrite() && !hasConfigurationChanged(this.initialData, this.data);
    if (isCurrentScopeVariableBackToOriginalValue) {
      this.isUpdated = false;
    }

    if (runValidation) this.runValidation();
  }

  @action
  delete() {
    this.data = { ...this.data };
    this.isShow = false;
  }

  @action
  deleteOrRevert() {
    this.markToDelete = this.isExistsAndInitByThisScope();
    this.isUpdated = this.isExistsAndInitByThisScope();

    if (!this.isOverwrite()) {
      this.delete();
      return;
    }

    // revert to original scope value
    this.data = {
      ...this.data,
      ...this.data.overwrites
    };

    this.runValidation();
  }

  @action
  restore() {
    this.data = cloneDeep(this.initialData) as ConfigurationProperty;
    this.isUpdated = false;
    this.runValidation();
  }

  @action
  runValidation() {
    this.error = validateConfigurationProperty(
      this.data,
      this.editScope,
      this.isExistsSensitiveAndSensitiveValueNotChanged(),
      this.variablesPolicies
    );

    if (this.error) return;

    if (this.configurationPropertiesStore.validateNameNotUnique(this)) {
      this.error = { message: 'settings.variables.name.already.exist', appearsUnder: 'name' };
    }
  }

  isOverwrite() {
    return !!this.overwrites;
  }

  isBelongThisScope() {
    // we want to treat DEPLOYMENT scope variable as equal to ENVIRONMENT scope variables (only relevant when edit ENVIRONMENT)
    const isDeploymentScopeInEnvironmentScope =
      this.editScope === ConfigurationScope.ENVIRONMENT && this.scope === ConfigurationScope.DEPLOYMENT;
    return (this.editScope === this.scope && this.editScopeId === this.scopeId) || isDeploymentScopeInEnvironmentScope;
  }

  isBelongUpperScope() {
    return !this.isBelongThisScope();
  }

  isBelongUpperScopeOrOverwrite() {
    return this.isBelongUpperScope() || this.isOverwrite();
  }

  isDropDownType() {
    return !!this.schema?.enum;
  }

  isEnvironmentOutput() {
    return this.schema?.format === 'ENVIRONMENT_OUTPUT';
  }

  isFreeText() {
    return !this.isDropDownType() && !this.data.schema?.format;
  }

  isExistsSensitive() {
    if (this.isEnvironmentOutput()) {
      return false;
    }
    return (this.initialData.id && this.initialData.isSensitive) || this.initialData.overwrites?.isSensitive;
  }

  isExistsAndInitByThisScope() {
    return this.isExist && this.initialData.scope === this.editScope && this.initialData.scopeId === this.editScopeId;
  }

  isExistsSensitiveAndSensitiveValueNotChanged() {
    return this.isExistsSensitive() && this.value === SENSITIVE_MASK;
  }

  get isNew() {
    // DEPLOYMENT scope is always new since they didn't save at the last deployment
    const isDeploymentScopeVariable = this.initialData.scope === ConfigurationScope.DEPLOYMENT;
    return !this.initialData.id || isDeploymentScopeVariable;
  }

  get isExist() {
    return !this.isNew;
  }

  get value() {
    return this.data.value;
  }

  get name() {
    return this.data.name;
  }

  get schema() {
    return this.data.schema;
  }

  get description() {
    return this.data.description;
  }

  get overwrites() {
    return this.data.overwrites;
  }

  get isReadonly() {
    return this.data.isReadonly;
  }

  get isUpperScopeReadonly() {
    return this.isBelongUpperScope() && this.isReadonly;
  }

  get isSensitive() {
    return this.data.isSensitive;
  }

  get isRequired() {
    return this.data.isRequired;
  }

  get regex() {
    return this.data.regex;
  }

  get type() {
    return this.initialData.type;
  }

  get scope() {
    return this.data.scope;
  }

  get scopeId() {
    return this.data.scopeId;
  }

  get initialScope() {
    return this.initialData.scope;
  }

  get initialScopeId() {
    return this.initialData.scopeId;
  }

  static createEmptyNew(
    configurationPropertiesStore: ConfigurationPropertiesStore,
    type: ConfigurationType,
    valueType: ConfigurationValueType
  ) {
    const property = this.createNew(configurationPropertiesStore, {
      type,
      schema: getSchemaValue(valueType)
    });

    property.error = { message: 'empty_variable' };

    return property;
  }

  static createNew(configurationPropertiesStore: ConfigurationPropertiesStore, props: Partial<ConfigurationProperty>) {
    const property = new ConfigurationPropertyStore(configurationPropertiesStore, {
      ...configurationPropertiesStore.configurationsScope,
      type: props.type
    });

    property.update(props);

    return property;
  }
}
