import { type VcsApi } from '@env0/vcs-service/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useApiClient from 'hooks/use-api-client';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';

export const useGetVcsConnections = () => {
  const orgId = useCurrentOrganizationId();
  const apiClient = useApiClient();

  return useQuery({
    queryKey: [orgId, 'vcs', 'connections'],
    queryFn: () => apiClient.vcsConnections.listVcsConnections(orgId!),
    enabled: Boolean(orgId)
  });
};

export const useGetVcsRepositories = (vcsType: string) => {
  const orgId = useCurrentOrganizationId();
  const apiClient = useApiClient();

  return useQuery({
    queryKey: [orgId, 'vcs', 'repositories', vcsType],
    queryFn: () => apiClient.vcsConnections.getVcsRepositories(orgId!, vcsType),
    enabled: !!vcsType,
    staleTime: Infinity
  });
};

export const useCreateVcsConnection = () => {
  const orgId = useCurrentOrganizationId();
  const projectId = useCurrentProjectId();
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (vcsConnection: VcsApi.CreateVcsConnection.Request.Body) =>
      apiClient.vcsConnections.createVcsConnection({ ...vcsConnection, projectId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [orgId, 'vcs'] });
    }
  });
};

export const useUpdateVcsConnection = () => {
  const orgId = useCurrentOrganizationId();
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, data }: { id: string; data: VcsApi.UpdateVcsConnection.Request.Body }) =>
      apiClient.vcsConnections.updateVcsConnection(id, data),
    onSuccess: () => () => queryClient.invalidateQueries({ queryKey: [orgId, 'vcs'] })
  });
};

export const useDeleteVcsConnection = () => {
  const orgId = useCurrentOrganizationId();
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => apiClient.vcsConnections.deleteVcsConnection(id),
    onSuccess: () => () => queryClient.invalidateQueries({ queryKey: [orgId, 'vcs'] })
  });
};
