import { theme as AntdTheme, type ThemeConfig } from 'antd';
import { THEME_VARIANTS } from 'constants/themes.constants';
import type { ThemeType } from 'types/theme.types';

const { darkAlgorithm, defaultAlgorithm } = AntdTheme;

export const getAntdTheme = (theme: ThemeType): ThemeConfig => {
  const currentTheme = THEME_VARIANTS[theme];

  return {
    algorithm: theme === 'dark' ? darkAlgorithm : defaultAlgorithm,
    token: {
      borderRadius: 4,
      colorBgContainerDisabled: currentTheme.primaryGray,
      colorBgMask: currentTheme.modalMaskGray,
      colorError: currentTheme.errorRed,
      colorPrimary: currentTheme.primaryGreen,
      colorInfo: currentTheme.primaryGreen,
      colorText: currentTheme.textBaseGray,
      colorLink: currentTheme.primaryBlue,
      colorLinkHover: currentTheme.primaryBlue,
      colorLinkActive: currentTheme.primaryBlue,
      fontFamily: "'ProximaNova', sans-serif",
      fontSize: 14
    },
    components: {
      Button: {
        colorText: currentTheme.primaryBlue,
        colorBgContainer: currentTheme.primaryWhite,
        colorBorder: currentTheme.primaryBlue,
        lineHeight: 1 // 100% of font size
      },
      Card: {
        boxShadowTertiary: 'none'
      },
      Collapse: {
        colorBgContainer: currentTheme.primaryWhite,
        colorFillAlter: currentTheme.primaryWhite,
        paddingSM: 24,
        padding: 24,
        colorTextHeading: currentTheme.textBaseGray
      },
      Dropdown: {
        fontSize: 12
      },
      Input: {
        controlHeight: 25
      },
      Menu: {
        itemSelectedBg: currentTheme.menuItemBlue,
        itemMarginInline: 0,
        itemBorderRadius: 0
      },
      Radio: {
        buttonColor: currentTheme.primaryBlue
      },
      Select: {
        colorBorder: currentTheme.lightGray,
        colorTextPlaceholder: currentTheme.textGray,
        controlHeight: 25,
        controlHeightSM: 25,
        fontSize: 12
      },
      InputNumber: {
        controlHeight: 25
      },
      Table: {
        colorFillAlter: currentTheme.primaryWhite,
        colorTextHeading: currentTheme.primaryBlue,
        fontWeightStrong: 500,
        colorBgContainer: currentTheme.primaryWhite
      },
      Tabs: {
        cardBg: currentTheme.backgroundGray,
        colorBorder: 'transparent'
      },
      Tooltip: {
        colorBgDefault: currentTheme.mode === 'dark' ? currentTheme.primaryGray : currentTheme.primaryBlack,
        colorTextLightSolid: currentTheme.mode === 'dark' ? currentTheme.primaryBlack : currentTheme.primaryWhite
      },
      Drawer: {
        colorBgElevated: currentTheme.mode === 'dark' ? currentTheme.primaryGray : currentTheme.primaryWhite
      }
    }
  };
};
