import React, { Fragment, type ReactNode } from 'react';
import type { Action, ActionImpl, KBarState } from 'kbar';
import {
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarResults,
  KBarSearch,
  useKBar,
  useMatches,
  useRegisterActions
} from 'kbar';
import isString from 'lodash/isString';
import styled from 'types/theme.types';
import useProjectNavigation from 'hooks/kbar/project-navigation.hooks';
import useModulesSearchActions from 'hooks/kbar/use-modules-actions.hooks';
import useTemplatesSearchActions from 'hooks/kbar/use-templates-kbar-actions.hooks';
import useBasicNavigationActions from 'hooks/kbar/basic-navigation-actions.hooks';
import useOrganizationSettingsTabsActions from 'hooks/kbar/use-settings-tabs-actions.hooks';
import { EventNames, track } from 'utils/analytics.utils';
import type { ActionAnalyticsData } from 'utils/kbar.utils';
import { createActionToDataMap } from 'utils/kbar.utils';
import { useEnvironmentSearchActions } from 'hooks/kbar/use-environment-search-actions.hooks';
import { KbarResultItem } from 'components/kbar/kbar-result-item';

let lastVisualState = 'hidden';

const trackKbarState = (state: KBarState) => {
  const visualState = state.visualState;

  if (visualState === 'showing' && lastVisualState !== 'showing') {
    track(EventNames.CMD_K_OPENED);
  }

  lastVisualState = visualState;
  return { searchQuery: state.searchQuery, isKbarOpen: ['showing', 'animating-in'].includes(visualState) };
};
export const KbarWrapper: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const { searchQuery, isKbarOpen } = useKBar(trackKbarState);
  const basicActions = useBasicNavigationActions();
  const settingsTabsActions = useOrganizationSettingsTabsActions();
  const projectsActions = useProjectNavigation();
  const templateActions = useTemplatesSearchActions({ enabled: isKbarOpen });
  const moduleActions = useModulesSearchActions({ enabled: isKbarOpen });
  const environmentActions = useEnvironmentSearchActions({ searchTerm: searchQuery, enabled: isKbarOpen });

  const allHooksAction = [
    basicActions,
    templateActions,
    projectsActions,
    moduleActions,
    settingsTabsActions,
    environmentActions
  ];
  const allHooksActionsFlattened = allHooksAction.flat();

  const actionAnalyticsMap = createActionToDataMap(allHooksActionsFlattened);

  useRegisterActions(warpActionWithAnalytics(allHooksActionsFlattened, actionAnalyticsMap), [
    allHooksAction,
    actionAnalyticsMap
  ]);

  return (
    <>
      <KBarPortal>
        <StyledKbarPositioner>
          <StyledAnimator>
            <StyledSearch data-e2e={`kbar-search`} />
            <SearchResults />
          </StyledAnimator>
        </StyledKbarPositioner>
      </KBarPortal>
      {children}
    </>
  );
};

const warpActionWithAnalytics = (actions: Action[], actionToDataMap: Record<string, ActionAnalyticsData>) =>
  actions.map(action => {
    if (!action.perform) return action;
    const analyticsPerform: Action['perform'] = (currentActionImpl: ActionImpl) => {
      track(EventNames.CMD_K_ITEM_CLICKED, actionToDataMap[action.id]);
      action!.perform!(currentActionImpl);
    };
    return { ...action, perform: analyticsPerform };
  });

const SearchResults = () => {
  const { results, rootActionId } = useMatches();

  return (
    <KBarResults
      data-e2e={'kbar-results'}
      items={results}
      onRender={({ item, active }) =>
        isString(item) ? (
          <SectionName data-e2e={`kbar-action-section-${item}`}>{item}</SectionName>
        ) : (
          <KbarResultItem action={item} active={active} currentRootActionId={rootActionId as string} />
        )
      }
    />
  );
};

const SectionName = styled.div`
  padding: 8px 16px;
  font-size: 10px;
  text-transform: uppercase;
  opacity: 0.5;
  color: ${({ theme }) => theme.primaryBlack};
  background: ${({ theme }) => (theme.mode === 'dark' ? theme.sideMenuBackground : theme.primaryWhite)};
`;

const StyledSearch = styled(KBarSearch)`
  padding: 12px 16px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  border: none;
  background: ${({ theme }) => theme.offWhite};
  color: ${({ theme }) => theme.primaryBlack};
  max-width: 600px;
  border-radius: 8px;
  overflow: hidden;
`;

const StyledAnimator = styled(KBarAnimator)`
  max-width: 600px;
  width: 100%;
  background-color: ${({ theme }) => theme.offWhite};
  border-radius: 8px;
`;

const StyledKbarPositioner = styled(KBarPositioner)`
  background-color: rgba(127, 127, 127, 0.5);
  z-index: 99;
`;
