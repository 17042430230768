import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { DashboardApi } from '@env0/dashboard-service/api';

export default (client: HttpClient) => ({
  getWidget: <REQUEST extends DashboardApi.Query.QueryParameters>(
    request: REQUEST
  ): Promise<DashboardApi.Query.QueryResult<REQUEST>> => {
    const searchParams = new URLSearchParams(request);
    return client
      .get<DashboardApi.Query.QueryResult<REQUEST>>(`dashboards?${searchParams.toString()}`)
      .then(returnData);
  },
  getPaginatedWidget: <REQUEST extends DashboardApi.Query.PaginationQueryParameters>(
    request: REQUEST
  ): Promise<DashboardApi.Query.PaginatedQueryResult<REQUEST>> => {
    const { queryStringParameters, ...rest } = request;
    const searchParams = new URLSearchParams({
      ...rest,
      ...Object.fromEntries(Object.entries(queryStringParameters ?? {}).filter(([_key, value]) => !!value))
    });
    return client
      .get<DashboardApi.Query.PaginatedQueryResult<REQUEST>>(`dashboards?${searchParams.toString()}`)
      .then(returnData);
  }
});
