import type { AxiosError, AxiosResponse } from 'axios';
import axios, { AxiosHeaders } from 'axios';
import { apiBaseUrl, appVersion, appVersionHash, stage } from 'constants/config';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import { StatusCodes } from 'http-status-codes';
import type ServiceContainer from 'services/service-container';
import { NotificationTypes } from 'types/notifications.types';
import axiosRetry, { exponentialDelay, isSafeRequestError } from 'axios-retry';
import { recentProjectIdsKey, recentProjectIdsLimit } from 'constants/local-storage.constants';
import { LAST_VISITED_ORGANIZATION_AND_USER_ID_STORAGE_KEY } from 'stores/mobx/organizations.store';
import BrowserStorage from 'services/BrowserStorage';

export type HttpClient = ReturnType<typeof createHttpClient>;

export const isGetRequest = (method?: string) => method && ['get', 'GET'].includes(method);

export const createHttpClient = () => {
  const axiosClient = axios.create();
  axiosClient.defaults.baseURL = apiBaseUrl;
  axiosClient.defaults.headers.common['X-Env0-Client-Version'] = appVersion ?? appVersionHash ?? stage;
  axiosClient.defaults.headers.common['X-Env0-Monitor'] = 'true';

  // set general retry
  // see more about isSafeRequestError  https://github.com/softonic/axios-retry/blob/2134e0665b147a28e9def7932d67d96f676d6cda/es/index.mjs#L38
  axiosRetry(axiosClient, {
    retries: 3,
    retryDelay: exponentialDelay,
    retryCondition: isSafeRequestError,
    onRetry: (_retryCount, error, requestConfig) => {
      console.info(`${requestConfig.method} ${requestConfig.url} returned an error.
      ${JSON.stringify(error.toJSON())}
      retrying...
      `);
    }
  });

  return axiosClient;
};

export const axiosClient = createHttpClient();

export function postConfigAxiosClient(axiosClient: HttpClient, service: ServiceContainer) {
  // unauthorized retry
  axiosRetry(axiosClient, {
    retries: 1,
    retryDelay: exponentialDelay,
    retryCondition: async error => {
      if (error.response?.status === StatusCodes.PAYMENT_REQUIRED) return false;

      if (error.response?.status !== StatusCodes.UNAUTHORIZED) {
        if (!error?.config?.hideNotification) {
          service.notificationStore.setNotification({
            notificationType: NotificationTypes.error,
            message: getApiErrorMessage(error)
          });
        }

        return false;
      }

      await service.authStore.silentRenewSession();

      return true;
    },
    onRetry: (_retryCount, error, requestConfig) => {
      console.info(`${requestConfig.method} ${requestConfig.url} returned an error.
      ${JSON.stringify(error.toJSON())}
      retrying...
      `);
    }
  });

  // this interceptor is for tracking the last visited projects
  // it saves the project ids of projects that some action was done in their page
  // action needs to be different from get
  axiosClient.interceptors.request.use(config => {
    if (config.method === 'get') {
      return config;
    }

    const pathname = window.location.pathname;

    // if the pathname is like /p/<projectId> I want to get the id
    const projectId = pathname.match(/\/p\/([^/]+)/)?.[1];

    if (!projectId) {
      return config;
    }

    const organizationId = BrowserStorage.getItem(LAST_VISITED_ORGANIZATION_AND_USER_ID_STORAGE_KEY).organization.id;
    const recentProjectIdsForAllOrganizations = BrowserStorage.getItem(recentProjectIdsKey) ?? {};
    const recentProjectIds = recentProjectIdsForAllOrganizations[organizationId] ?? [];

    const index = recentProjectIds.indexOf(projectId);
    if (index !== -1) {
      // if exists in the array, make first in the array
      recentProjectIds.splice(index, 1);
      recentProjectIds.unshift(projectId);
    } else {
      recentProjectIds.unshift(projectId);
    }

    if (recentProjectIds.length > recentProjectIdsLimit) {
      recentProjectIds.pop();
    }

    BrowserStorage.setItem(recentProjectIdsKey, {
      ...recentProjectIdsForAllOrganizations,
      [organizationId]: recentProjectIds
    });

    return config;
  });

  axiosClient.interceptors.request.use(config => {
    if (!config.headers) config.headers = new AxiosHeaders();
    config.headers.Authorization = `Bearer ${service.authStore.getAccessToken()}`;

    if (isGetRequest(config.method)) {
      config.sourcePageRoute = window.location.pathname + window.location.search;
    }

    return config;
  });

  axiosClient.interceptors.response.use(
    config => {
      return config;
    },
    (error: AxiosError) => {
      if (error?.response?.status === StatusCodes.PAYMENT_REQUIRED) {
        if (error.config?.sourcePageRoute && isGetRequest(error.config.method)) {
          service.payGateStore.cachePageRoute(error.config.sourcePageRoute, error);
        }

        service.payGateStore.cacheOrThrowError(error);
        service.payGateStore.setActivePayGateError(error);
      }
      throw error;
    }
  );
}

export const returnData = <T>(response: AxiosResponse<T>): T => response.data;

export const getResponseErrorStatus = (err: AxiosError): number | null => {
  return err.response ? err.response.status : null;
};

export const getResponseErrorData = (err: AxiosError) => {
  return (err.response ? err.response.data : '') as string;
};

export const getApiErrorMessage = (error: AxiosError<any>): string => {
  let message = '';

  if (!error.response?.data) {
    message = error.message;
  } else {
    const { data } = error.response;
    if (isArray(data)) data.forEach(line => (message += `${line}\n`));
    else if (isString(data)) message += `${data}\n`;
    else if (data.message) message += `${data.message}\n`;
  }

  return message;
};
