import App from 'components/app';
import {
  isDevNodeEnv,
  appVersion,
  appVersionHash,
  isDebug,
  segmentSourceKey,
  sentryDSN,
  stage
} from 'constants/config';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { load } from 'utils/analytics.utils';
import * as Sentry from '@sentry/react';
import { CaptureConsole, ContextLines, Debug, ExtraErrorData, HttpClient } from '@sentry/integrations';
import { addUserSnapshotContextToEvent, parseUrlForFingerprint, updateFingerprint } from 'utils/sentry.utils';
import throttle from 'lodash/throttle';
import { setLogger as setRetryLogger } from '@env0/common-retry';
import { setUseWhatChange } from '@simbathesailor/use-what-changed';

// ORDER IS IMPORTANT
import 'antd/lib/style/reset.css';
import 'assets/styles/antd.customize.less';
import 'assets/styles/index.scss';
import { AxiosError } from 'axios';
import type { ErrorEvent, EventHint } from '@sentry/types';

Object.assign(window, { env0FrontendVersion: appVersion, env0FrontendVersionHash: appVersionHash });

// Loading New Relic
declare global {
  interface Window {
    NREUM: any;
  }
}

const UPLOAD_SCREENSHOT_THROTTLE = 5 * 60 * 1000; // 5 minutes
const throttledAddUserSnapshotContextToEvent = throttle(addUserSnapshotContextToEvent, UPLOAD_SCREENSHOT_THROTTLE);

// Load Segment
if (segmentSourceKey) {
  load(segmentSourceKey);
}

if (sentryDSN) {
  const sentryIntegrations = [
    new CaptureConsole(),
    new ContextLines(),
    new ExtraErrorData(),
    new HttpClient(),
    new Sentry.BrowserProfilingIntegration(),
    new Sentry.BrowserTracing()
  ];

  if (stage !== 'prod') {
    sentryIntegrations.push(new Debug() as any);
  }

  Sentry.init({
    dsn: sentryDSN,
    environment: stage,
    release: `env0@${appVersion}@${appVersionHash}`,
    integrations: sentryIntegrations as any[],
    normalizeDepth: 5,
    beforeSend: async function (event, hint) {
      addRequestUrlCustomTag(event, hint);

      // NOTE: avoid console.log here, as long as CaptureConsole is enabled, it will cause infinite loop
      updateFingerprint(event, hint);
      if (event.level === 'error' || event.level === 'fatal') {
        await throttledAddUserSnapshotContextToEvent(event);
      }

      return event;
    },
    tracesSampleRate: 0.5,
    profilesSampleRate: 0.3,
    ignoreErrors: ["Failed to read the 'cssRules' property from 'CSSStyleSheet'"]
  });
}

// global logger to retry function so whoever uses it will have logs
setRetryLogger({ info: console.info, warning: console.warn, debug: console.debug });

const isCypress = !!(window as { Cypress?: any }).Cypress;
if (isDebug && !isCypress) {
  setUseWhatChange(isDevNodeEnv); // use this library :) it is better than 'why-did-you-render'

  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

createRoot(document.getElementById('root') as HTMLElement).render(<App />);

const addRequestUrlCustomTag = (event: ErrorEvent, hint: EventHint) => {
  const originalException = hint.originalException;
  let url: string | undefined;

  if (originalException instanceof AxiosError && originalException?.config?.url) {
    url = originalException.config.url;
  } else if (event.exception?.values?.[0]?.mechanism?.type === 'http.client') {
    url = event.request?.url;
  }

  if (url) {
    Sentry.withScope(scope => {
      scope.setTag('request.url', parseUrlForFingerprint(url));
    });
  }
};
