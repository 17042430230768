import type ApiClient from 'services/api-client';
import { BlueprintsStore } from 'stores/mobx/blueprints.store';
import { CostsStore } from 'stores/mobx/costs.store';
import { DeploymentStepsStore } from 'stores/mobx/deployment-steps.store';
import { EnvironmentsStore } from 'stores/mobx/environments.store';
import { NetworkStore } from 'stores/mobx/network.store';
import { NotificationStore } from 'stores/mobx/notifications.store';
import { OrganizationsStore } from 'stores/mobx/organizations.store';
import { ProjectsStore } from 'stores/mobx/projects.store';
import { UserStore } from 'stores/mobx/user.store';
import { GraphqlEventsStore } from 'stores/mobx/events.store';
import { SchedulingStore } from 'stores/mobx/scheduling.store';
import { AuthStore } from 'stores/mobx/auth.store';
import { TeamsStore } from 'stores/mobx/teams.store';
import { PoliciesStore } from 'stores/mobx/policies.store';
import { NotificationSettingsStore } from 'stores/mobx/notification-settings.store';
import { ConfigurationPropertiesStore } from 'stores/mobx/configuration-properties.store';
import { NavBarStore } from 'stores/mobx/navbar.store';
import { WorkflowVariablesStore } from 'stores/mobx/workflow-variables-store';
import { PayGateStore } from 'stores/mobx/pay-gate.store';
import { EnvironmentImportVariablesStore } from 'stores/mobx/environment-import-variables-store';

class ServiceContainer {
  public navbarStore: NavBarStore;
  public networkStore: NetworkStore;
  public userStore: UserStore;
  public organizationsStore: OrganizationsStore;
  public projectsStore: ProjectsStore;
  public blueprintsStore: BlueprintsStore;
  public environmentsStore: EnvironmentsStore;
  public deploymentStepsStore: DeploymentStepsStore;
  public notificationStore: NotificationStore;
  public payGateStore: PayGateStore;
  public costsStore: CostsStore;
  public graphqlEventsStore: GraphqlEventsStore;
  public schedulingStore: SchedulingStore;
  public authStore: AuthStore;
  public teamsStore: TeamsStore;
  public policiesStore: PoliciesStore;
  public notificationSettingsStore: NotificationSettingsStore;
  public configurationPropertiesStore: ConfigurationPropertiesStore;
  public workflowVariablesStore: WorkflowVariablesStore;
  public environmentImportVariablesStore: EnvironmentImportVariablesStore;

  constructor(public apiClient: ApiClient) {
    this.authStore = new AuthStore(this);
    this.navbarStore = new NavBarStore(this);
    this.networkStore = new NetworkStore(this);
    this.userStore = new UserStore(this);
    this.organizationsStore = new OrganizationsStore(this);
    this.projectsStore = new ProjectsStore(this);
    this.blueprintsStore = new BlueprintsStore(this);
    this.environmentsStore = new EnvironmentsStore(this);
    this.deploymentStepsStore = new DeploymentStepsStore(this);
    this.notificationStore = new NotificationStore(this);
    this.payGateStore = new PayGateStore(this);
    this.costsStore = new CostsStore(this);
    this.graphqlEventsStore = new GraphqlEventsStore(this);
    this.schedulingStore = new SchedulingStore(this);
    this.teamsStore = new TeamsStore(this);
    this.policiesStore = new PoliciesStore(this);
    this.notificationSettingsStore = new NotificationSettingsStore(this);
    this.configurationPropertiesStore = new ConfigurationPropertiesStore(this);
    this.workflowVariablesStore = new WorkflowVariablesStore(this);
    this.environmentImportVariablesStore = new EnvironmentImportVariablesStore(this);
  }
}

export default ServiceContainer;
