import React from 'react';
import styled from 'types/theme.types';
import type { Notification } from 'stores/rq/app-notifications';
import TrackableLink from 'components/common/trackable-link';
import { EventNames } from 'utils/analytics.utils';

interface Props {
  notification: Notification;
}

export const NotificationRow: React.FC<Props> = ({ notification: { title, description, link } }) => {
  return (
    <VersionRowContainer>
      <div className="content">
        <div className="title">{title}</div>
        <div className="description">{description}</div>
        <TrackableLink
          trackingData={{ eventName: EventNames.IN_APP_WHATS_NEW_VIEW_ITEM_CLICKED, eventProperties: { link: link } }}
          url={link}
          data-e2e="learn-more"
          messageId="learn.more"
          className="learn-more"
        />
      </div>
    </VersionRowContainer>
  );
};

const VersionRowContainer = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  padding: 32px;
  --duration: 0.5s;
  transition-property: background-color;
  transition-duration: var(--duration);

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    .content {
      transform: translateX(10px);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    transition-property: transform;
    transition-duration: var(--duration);

    .title {
      font-size: 24px;
    }

    .description,
    .learn-more {
      text-align: left;
      font-size: 16px;
    }
  }
`;
