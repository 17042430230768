import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, type CheckboxProps, Tree, type TreeProps as AntdTreeProps } from 'antd';
import type { DataNode } from 'antd/lib/tree';
import type { NodeProps } from 'components/common/utils/map-items-to-antd-tree-nodes';
import styled from 'types/theme.types';
import SearchBar from 'components/common/search-bar';
import ColorText from 'components/common/color-text';
import compact from 'lodash/compact';
import isNil from 'lodash/isNil';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { FormattedMessage } from 'react-intl';
import type { Arg1 } from 'tsargs';
import flatMap from 'lodash/flatMap';
import without from 'lodash/without';
import uniq from 'lodash/uniq';
import { useUncontrolled } from 'hooks/use-uncontrolled';

interface TreeProps {
  data: NodeProps[];
  disabled?: boolean;
  onLoad?: (key: string) => Promise<NodeProps[]>;
  onCheck?: (checkedKeys: string[]) => void;
  checkedKeys?: string[];
  defaultCheckedKeys?: string[];
  withCheckAll?: boolean;
  allowOnlySingleCheck?: boolean;
}

type TransformFn = (
  node: NodeProps,
  transformChildren: NodeProps[] | undefined,
  parentPath: string[]
) => Partial<NodeProps> | void;

function transformTreeData(list: NodeProps[], transformFn: TransformFn, parentPath: string[] = []): NodeProps[] {
  return compact(
    list?.map((node: NodeProps) => {
      const children =
        node.children && transformTreeData(node.children, transformFn, [...parentPath, node.key as string]);

      const transformNode = transformFn(node, children, parentPath);

      // Use to remove node from tree
      if (isNil(transformNode)) return null;

      return {
        ...node,
        children,
        ...transformNode
      };
    })
  );
}

const mapKeysRecursively: (item: DataNode) => React.Key[] = (item: DataNode) => [
  item.key,
  ...flatMap(item.children, mapKeysRecursively)
];

const mapKeys = (treeData?: DataNode[]) => flatMap(treeData, mapKeysRecursively);

const SelectedTree: React.FC<TreeProps> = ({
  data,
  onLoad,
  onCheck: onCheck_,
  checkedKeys: checkedKeys_,
  defaultCheckedKeys,
  disabled,
  withCheckAll,
  allowOnlySingleCheck
}) => {
  const [treeData, setTreeData] = useState<NodeProps[]>(data);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const [checkedKeys, onCheck] = useUncontrolled({
    value: checkedKeys_,
    defaultValue: defaultCheckedKeys,
    onChange: onCheck_
  });

  const loadData = useCallback(
    async ({ key }: NodeProps) => {
      const newChildren = await onLoad?.(key as string);

      const combineChildrenForSpecificKey: TransformFn = (node, oldChildren) => ({
        children: node.key === key ? [...(oldChildren ?? []), ...(newChildren ?? [])] : oldChildren
      });

      const data = transformTreeData(treeData, combineChildrenForSpecificKey);
      setTreeData(data);
    },
    [onLoad, treeData]
  );

  useEffect(() => {
    setTreeData(data);
    const expandedKeysSet = new Set<string>();

    const findAllExpandedKeys: TransformFn = (node, _, parentPath) => {
      if (checkedKeys?.includes(node.key as string)) parentPath.forEach(item => expandedKeysSet.add(item));
    };

    transformTreeData(data, findAllExpandedKeys);
    setExpandedKeys(Array.from(expandedKeysSet));
    // expanded selected keys only on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const onExpand = useCallback((newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys as string[]);
    setAutoExpandParent(false);
  }, []);

  const onSearch = useCallback(
    (text: string) => {
      const expandedKeysSet = new Set<string>();

      const highlightSearchTextAndHideOther: TransformFn = (node, children, parentPath) => {
        if (!isString(node.title)) return { title: node.title };

        const isNodeMatch = text && node.title?.toLowerCase().includes(text.toLowerCase());

        if (isNodeMatch) parentPath.forEach(item => expandedKeysSet.add(item));

        // Filter out nodes without children or match
        if (!isNodeMatch && !isEmpty(text) && size(children) === 0) {
          return;
        }

        return {
          title: isNodeMatch ? <ColorText text={node.title ?? ''} colorText={text} /> : node.title
        };
      };

      const newData = transformTreeData(data, highlightSearchTextAndHideOther);

      setTreeData(newData);
      setExpandedKeys(Array.from(expandedKeysSet));
      setAutoExpandParent(true);
    },
    [data]
  );

  const allKeys = useMemo(() => mapKeys(data), [data]);
  const allChecked = useMemo(() => checkedKeys?.length === allKeys.length, [checkedKeys, allKeys]);
  const someChecked = useMemo(() => !!checkedKeys?.length && !allChecked, [checkedKeys, allChecked]);

  const CheckAllCheckbox = () => {
    const onCheckAll = (checkAllEvent: Arg1<CheckboxProps['onChange']>) => {
      const allChecked = !!checkAllEvent?.target.checked;
      onCheck?.(allChecked ? allKeys.map(String) : []);
    };

    return (
      <StyledCheckbox
        disabled={disabled}
        onChange={onCheckAll}
        indeterminate={someChecked}
        checked={allChecked}
        data-e2e="checkbox-check-all">
        <FormattedMessage id={'checkbox.group.select.all'} />
      </StyledCheckbox>
    );
  };

  const onCheckChange = useCallback<NonNullable<AntdTreeProps['onCheck']>>(
    (_, { checked, node: { key } }) => {
      if (allowOnlySingleCheck) {
        onCheck?.(checked ? [String(key)] : []);
        return;
      }

      const keys = checkedKeys ?? [];
      onCheck?.(checked ? uniq([...keys, String(key)]) : without(keys, String(key)));
    },
    [checkedKeys, onCheck, allowOnlySingleCheck]
  );

  const onClick = useCallback<NonNullable<AntdTreeProps['onClick']>>(
    (_, { key }) => {
      const expanded = expandedKeys.includes(String(key));
      onExpand(expanded ? without(expandedKeys, key) : uniq([...expandedKeys, key]));
    },
    [expandedKeys, onExpand]
  );

  return (
    <StyledContainer>
      <SearchBar onFilter={onSearch} placeholder={'search'} disabled={disabled} />
      {withCheckAll && <CheckAllCheckbox />}
      <StyledDiv>
        <Tree
          disabled={!!disabled}
          onExpand={onExpand}
          onCheck={onCheckChange}
          loadData={onLoad ? loadData : undefined}
          treeData={treeData}
          checkable
          showIcon
          expandedKeys={expandedKeys}
          checkedKeys={checkedKeys}
          autoExpandParent={autoExpandParent}
          checkStrictly
          selectable={false}
          onClick={onClick}
        />
      </StyledDiv>
    </StyledContainer>
  );
};

const StyledDiv = styled.div`
  height: 250px;
  overflow: scroll;
  border: 1px solid ${({ theme }) => theme.lightGray};
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 8px;
`;

const StyledContainer = styled.div``;

export default SelectedTree;
