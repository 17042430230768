import React from 'react';
import CommonModal from 'components/common/modal';
import Button from 'components/common/button';
import { FormattedMessage } from 'react-intl';
import TemplateWizardStepVCS from 'components/templates/templates-wizard/vcs/template-wizard-step-vcs';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import type {
  ConfigurationTemplateType,
  User,
  ConfigurationTemplateWithoutName,
  ConfigurationTemplate
} from 'types/api.types';
import { useConfigurationTemplateWizardHook } from 'hooks/use-configuration-template-wizard.hook';
import ButtonsContainer from 'components/common/buttons-container';
import styled from 'types/theme.types';
import { BlockingBackground } from 'components/common/blocking-background';

export interface VcsTemplateModalProps {
  hideModal: () => void;
  onSave: (configurationTemplate: ConfigurationTemplateWithoutName) => void;
  open: boolean;
  blueprint?: ConfigurationTemplate & { id: string; author: User };
  type: ConfigurationTemplateType;
}

export const VcsTemplateModal: React.FunctionComponent<VcsTemplateModalProps> = ({
  hideModal,
  open,
  onSave,
  blueprint,
  type,
  ...otherProps
}) => {
  const currentOrganizationId = useCurrentOrganizationId();
  const configurationTemplateWizard = useConfigurationTemplateWizardHook({ blueprint, type });

  const onSaveBlueprint = async () => {
    let templateToSave: Omit<ConfigurationTemplateWithoutName, 'name'>;

    if (blueprint) {
      templateToSave = { ...blueprint, ...configurationTemplateWizard.vcsStepForm.getValues() };
    } else {
      templateToSave = {
        type,
        organizationId: currentOrganizationId!,
        ...configurationTemplateWizard.vcsStepForm.getValues()
      };
    }

    onSave(templateToSave);
    hideModal();
  };

  return (
    <CommonModal
      open={open}
      titleId={`projects.settings.policies.configure-${type}`}
      footer={
        <ButtonsContainer>
          <Button onClick={hideModal}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            disabled={!configurationTemplateWizard.isSaveEnabled}
            onClick={onSaveBlueprint}
            type="primary"
            data-e2e={'vcs-template-modal-save-button'}>
            <FormattedMessage id="apply" />
          </Button>
        </ButtonsContainer>
      }
      onCancel={hideModal}
      destroyOnClose
      width={1000}
      centered
      {...otherProps}>
      <TemplateWizardStepVCS
        CustomBlockingBackground={CustomBlockingBackground}
        vcsForm={configurationTemplateWizard.vcsStepForm}
        templateType={type}
        isNewTemplate={!blueprint}
      />
    </CommonModal>
  );
};

const CustomBlockingBackground = styled(BlockingBackground)`
  width: 100%;
  height: 100%;
`;
