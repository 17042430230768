import { useCallback } from 'react';
import { MacCommandOutlined, SearchOutlined } from '@ant-design/icons';
import { useKBar } from 'kbar';
import Button from 'components/common/button';
import useOperatingSystem from 'hooks/use-operating-system';

export const KbarSearchButton = () => {
  const { query } = useKBar();
  const { isMacOs } = useOperatingSystem();

  const toggleKbar = useCallback(() => query?.toggle(), [query]);

  return (
    <Button onClick={toggleKbar} data-e2e="search-button" icon={<SearchOutlined className="search" />}>
      <span>Search {isMacOs ? <MacCommandOutlined data-e2e={'kbar-search-mac-command-icon'} /> : 'Ctrl'} + K</span>
    </Button>
  );
};
