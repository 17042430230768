import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from 'antd';
import React from 'react';
import type { TextProps } from 'antd/lib/typography/Text';
import styled from 'types/theme.types';
import CopyOutlined from '@ant-design/icons/lib/icons/CopyOutlined';
import withLabel from 'components/common/form-controlled/with-label.hoc';

export type CopyableIdProps = {
  text: string;
  entityName?: string;
  copyIcon?: React.ReactNode;
  showText?: boolean;
  showCodeStyle?: boolean;
  copyableOverride?: Partial<TextProps['copyable']>;
  className?: string;
};
const CopyableId: React.FC<CopyableIdProps> = ({
  text,
  entityName,
  copyIcon,
  showText = true,
  showCodeStyle = false,
  copyableOverride,
  ...props
}) => {
  const intl = useIntl();
  return (
    <Text
      code={showCodeStyle}
      data-e2e={`copyable-${text}`}
      copyable={{
        text: text,
        icon: copyIcon
          ? [copyIcon, copyIcon]
          : [
              <DefaultCopyIcon data-e2e={`copyable-${text}-button`} />,
              <DefaultCopyIcon data-e2e={`copyable-${text}-button`} />
            ],
        tooltips: (
          <FormattedMessage
            id={'copy.id.tooltip.text'}
            values={{ entityType: entityName ? intl.formatMessage({ id: entityName }) : '' }}
          />
        ),
        ...(copyableOverride as {}),
        ...props
      }}
      className={props.className}>
      {showText ? text : ''}
    </Text>
  );
};

const Text = styled(Typography.Text)`
  font-size: inherit;
`;

const DefaultCopyIcon = styled(CopyOutlined)`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: 500;
`;

const CopyableIdWithLabelContainer = styled.div`
  margin-bottom: 1em;
`;

export const CopyableIdWithLabel = withLabel<CopyableIdProps>(({ ...props }) => {
  return (
    <CopyableIdWithLabelContainer>
      <CopyableId {...props} />
    </CopyableIdWithLabelContainer>
  );
});

export default CopyableId;
