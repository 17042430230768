import useApiClient from 'hooks/use-api-client';
import { useQuery } from '@tanstack/react-query';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';

const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();

  const baseKey = [orgId, 'agents'] as const;
  const cacheKeys = {
    all: [...baseKey, 'all'],
    assignments: [...baseKey, 'assignments']
  } as const;

  return cacheKeys;
};

export const useGetAgents = (shouldFetchAgents: boolean) => {
  const apiClient = useApiClient();
  const { all } = useCacheKeys();
  return useQuery({
    queryKey: all,
    queryFn: ({ queryKey: [orgId] }) => apiClient.agents.listAgents(orgId),
    enabled: shouldFetchAgents
  });
};

export const useGetProjectAgentAssignment = (enabled: boolean) => {
  const apiClient = useApiClient();
  const { assignments } = useCacheKeys();
  return useQuery({
    queryKey: assignments,
    queryFn: ({ queryKey: [orgId] }) => apiClient.agents.getProjectsAssignments(orgId),
    enabled: enabled
  });
};
