import React, { lazy, Suspense } from 'react';
import { QueryClientProvider as ReactQueryProvider } from '@tanstack/react-query';
import { initializeQueryClient } from 'stores/rq/common/initialize-query-client';
import { isReactQueryDevToolsEnabled } from 'constants/config';

const queryClient = initializeQueryClient();

export const getQueryClientInstance = () => queryClient;

const LazyQueryClientDevTools = () => {
  const ReactQueryDevtools = lazy(() => import('stores/rq/common/ReactQueryDevtools'));
  return (
    <Suspense fallback={<div>Loading React Query DevTools...</div>}>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />;
    </Suspense>
  );
};

export const QueryClientProvider = ({ children }: { children: React.ReactNode }) => (
  <ReactQueryProvider client={queryClient}>
    {children}
    {isReactQueryDevToolsEnabled && <LazyQueryClientDevTools />}
  </ReactQueryProvider>
);
