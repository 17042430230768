import { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import toUpper from 'lodash/toUpper';
import { Forbidden } from 'http-errors';
import { CONFLICT } from 'http-status-codes';
import useStores from 'hooks/use-stores.hooks';
import { setLink } from 'components/common/link';
import { links } from 'constants/external-links';
import { NotificationTypes } from 'types/notifications.types';
import { EventNames, track } from 'utils/analytics.utils';
import type { VcsIntegrationEvent } from 'components/templates/analytics';
import type { GitHostedProviderTypes, GitProviderTypes } from 'types/api.types';
import { TokenTypes } from 'types/api.types';
import { getResponseErrorStatus } from 'services/api-client/client';
import { useAsyncPromise } from 'hooks/use-async-promise';
import type { AxiosError } from 'axios';
import type { VCSForm } from 'components/templates/templates-wizard/common/template-wizard.types';
import { getInitialGitProvider, isGitProviderUsingToken } from 'utils/vcs.utils';
import type { NotificationStore } from 'stores/mobx/notifications.store';
import { isDevNodeEnv } from 'constants/config';
import { useGenerateGitAuthToken, useGetTokensAsync } from 'stores/rq/vcs-auth-tokens';

export const providersSupportingRepositoryIntegration: Set<GitProviderTypes> = new Set<GitProviderTypes>([
  'GitHub',
  'BitBucket',
  'GitLab',
  'AzureDevOps'
]);

export const providersSupportingVcsRepositoryIntegration: Set<GitProviderTypes> = new Set<GitHostedProviderTypes>([
  'GitLabEnterprise',
  'GitHubEnterprise',
  'BitBucketServer'
]);

const trackVcsEvent = (event: VcsIntegrationEvent) => {
  track(event.eventName, event.eventProperties); // This is our regular tracking
  track(`${toUpper(event.eventProperties.provider)}_${event.eventName}`); // This event is sent to Hubspot for property updates
};

const trackVcsIntegrationSuccess = (provider: GitProviderTypes) => {
  trackVcsEvent({ eventName: EventNames.VCS_INTEGRATION_SUCCESS, eventProperties: { provider } });
};

type GitIntegrationFailureType = 'admin-required' | 'failure' | 'blocked' | 'token-exists';

const trackIntegrationFailure = (provider: GitProviderTypes, failureType: GitIntegrationFailureType) => {
  let failureReason = 'failure';

  if (provider === 'GitLab') failureReason = failureType === 'token-exists' ? 'token-exists' : 'no-gitlab-token';
  if (provider === 'GitHub')
    failureReason = failureType === 'admin-required' ? 'admin-required' : 'no-github-installation-id';
  if (failureType === 'blocked') failureReason = 'popup-blocked';

  trackVcsEvent({
    eventName: EventNames.VCS_INTEGRATION_FAILURE,
    eventProperties: { provider, failureReason }
  });
};

const alertIntegrationFailure = (
  provider: GitProviderTypes,
  failureType: GitIntegrationFailureType,
  notificationStore: NotificationStore
) => {
  const message = isDevNodeEnv // VCS integration is not possible in localhost
    ? `templates.add.new.field.organization.vcs.localhost.failure`
    : `templates.add.new.field.organization.${provider.toLocaleLowerCase()}.${failureType}`;
  const messageVariables =
    failureType === 'admin-required' ? { ...setLink(links.docs.TEMPLATES.GITHUB_INTEGRATION) } : undefined;

  notificationStore.setNotification({
    notificationType: NotificationTypes.error,
    message,
    messageVariables
  });
};

export type AuthType = TokenTypes;

type GitIntegratedVcsProviderTypes = 'GitHub' | 'GitLab' | 'BitBucket' | 'AzureDevOps';

// this hook should only hold the state of the VCS step while it's active,
// anything that should persist between steps should be on the `form`
export const useGitProvider = ({ watch, useManualField, setValue }: VCSForm) => {
  const { organizationsStore, notificationStore, blueprintsStore } = useStores();
  const currentOrganizationId = organizationsStore.currentOrganizationId as string;

  const repositories = blueprintsStore.repositoriesByOrganizationId[currentOrganizationId] || [];

  const { tokenId, repository } = watch();
  const [githubInstallationId, setGithubInstallationId] = useManualField<number | null>('githubInstallationId');
  const [isGitLab, setIsGitLab] = useManualField<boolean>('isGitLab');
  const [isAzureDevOps, setIsAzureDevOps] = useManualField<boolean>('isAzureDevOps');
  const [isHelmRepository, setIsHelmRepository] = useManualField<boolean>('isHelmRepository');
  const [bitbucketClientKey, setBitbucketClientKey] = useManualField<string | null>('bitbucketClientKey');
  const [isBitbucketServer, setIsBitbucketServer] = useManualField<boolean>('isBitbucketServer');
  const [isGitLabEnterprise, setIsGitLabEnterprise] = useManualField<boolean>('isGitLabEnterprise');
  const [isGitHubEnterprise, setIsGitHubEnterprise] = useManualField<boolean>('isGitHubEnterprise');

  const [isFormBlocked, setIsFormBlocked] = useState(false);
  const [isLoadingRepositories, setIsLoadingRepositories] = useState(false);
  const [providerBeingIntegrated, setProviderBeingIntegrated] = useState<GitProviderTypes>();
  const [gitProvider, setGitProvider] = useState<GitProviderTypes | undefined>(
    getInitialGitProvider({
      repository,
      githubInstallationId,
      isGitLab,
      isAzureDevOps,
      isHelmRepository,
      bitbucketClientKey,
      isBitbucketServer,
      isGitLabEnterprise,
      isGitHubEnterprise
    })
  );

  const { mutateAsync: generateGitAuthToken } = useGenerateGitAuthToken();
  const { promise: getTokens } = useGetTokensAsync();

  const startVcsIntegration = useCallback((provider: GitProviderTypes) => {
    setProviderBeingIntegrated(provider);
  }, []);
  const finishVcsIntegration = useCallback(() => setProviderBeingIntegrated(undefined), []);

  const fetchRepositories = useCallback(
    async (gitProvider: GitProviderTypes, token?: any) => {
      setIsLoadingRepositories(true);
      try {
        if (gitProvider === 'GitHub') {
          await blueprintsStore.getGithubRepositories(token);
        } else if (gitProvider === 'GitLab' && token) {
          await blueprintsStore.getGitlabRepositories(token);
        } else if (gitProvider === 'BitBucket') {
          await blueprintsStore.getBitbucketRepositories(token);
        } else if (gitProvider === 'AzureDevOps' && token) {
          await blueprintsStore.getAzureDevOpsRepositories(token);
        }
      } finally {
        setIsLoadingRepositories(false);
      }
    },
    [blueprintsStore]
  );

  useAsyncPromise(async () => {
    if (gitProvider) {
      setIsFormBlocked(false);
      await fetchRepositories(gitProvider, isGitProviderUsingToken(gitProvider) ? tokenId : undefined);
    }
  }, [fetchRepositories, gitProvider, tokenId]);

  useEffect(() => setIsFormBlocked(!!providerBeingIntegrated), [providerBeingIntegrated]);

  const resetVCSData = useCallback(
    (type: GitProviderTypes) => {
      setValue('path', '');
      setValue('repository', '', { shouldValidate: false });
      setValue('revision', '', { shouldValidate: false });
      setValue('vcsConnectionId', null);

      blueprintsStore.setRepositories([], currentOrganizationId);

      if (type !== 'GitHub') setGithubInstallationId(null);
      if (type !== 'BitBucket') setBitbucketClientKey(null);

      // Reset the token data in any case
      setValue('tokenName', '');
      setValue('tokenId', '');

      setIsBitbucketServer(type === 'BitBucketServer');
      setIsGitLab(type === 'GitLab');
      setIsAzureDevOps(type === 'AzureDevOps');
      setIsHelmRepository(type === 'HelmRepository');
      setIsGitLabEnterprise(type === 'GitLabEnterprise');
      setIsGitHubEnterprise(type === 'GitHubEnterprise');
    },
    // From some reason all the setters function reference are changing and causing onSuccess ref to change => auth popup to jump twice. So I removed it from the deps array for now.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue, blueprintsStore, currentOrganizationId]
  );

  const setGitProviderAndResetVCSData = useCallback(
    (provider: GitProviderTypes) => {
      setGitProvider(provider);
      resetVCSData(provider);
    },
    [resetVCSData]
  );

  const onVcsIntegrationFailure = useCallback(
    (provider: GitProviderTypes, failureType: GitIntegrationFailureType = 'failure') => {
      alertIntegrationFailure(provider, failureType, notificationStore);
      trackIntegrationFailure(provider, failureType);
      finishVcsIntegration();
    },
    [notificationStore, finishVcsIntegration]
  );

  const onVcsIntegrationSuccess = useCallback(async () => {
    if (providerBeingIntegrated === 'GitHub') {
      try {
        const installationId = blueprintsStore.getGithubNewInstallationId();
        trackVcsIntegrationSuccess('GitHub');
        setGitProviderAndResetVCSData('GitHub');
        // For new integrations, the installation ID doesn't exist on the template until it is saved.
        // So in order for us to be able to see the new repositories, we must query them by the new installation ID
        await fetchRepositories('GitHub', installationId);
      } catch (e) {
        onVcsIntegrationFailure('GitHub', e instanceof Forbidden ? 'admin-required' : 'failure');
      }
    }

    if (providerBeingIntegrated === 'GitLab') {
      try {
        const authCode = blueprintsStore.getGitlabAuthorizationCode();
        const token = await generateGitAuthToken({ gitProvider: 'GitLab', authCode });

        trackVcsIntegrationSuccess('GitLab');
        setGitProviderAndResetVCSData('GitLab');
        setValue('tokenId', token.id, { shouldValidate: false });
        setValue('tokenName', token.name, { shouldValidate: false });
      } catch (error) {
        console.log('GitLab auth error', error);
        onVcsIntegrationFailure(
          'GitLab',
          getResponseErrorStatus(error as AxiosError) === CONFLICT ? 'token-exists' : 'failure'
        );
      }
    }

    if (providerBeingIntegrated === 'AzureDevOps') {
      try {
        const authCode = blueprintsStore.getAzureDevOpsAuthorizationCode();
        const token = await generateGitAuthToken({ gitProvider: 'AzureDevOps', authCode });

        trackVcsIntegrationSuccess('AzureDevOps');
        setGitProviderAndResetVCSData('AzureDevOps');

        setValue('tokenId', token.id, { shouldValidate: false });
        setValue('tokenName', token.name, { shouldValidate: false });
      } catch (e) {
        onVcsIntegrationFailure(
          'AzureDevOps',
          getResponseErrorStatus(e as AxiosError) === CONFLICT ? 'token-exists' : 'failure'
        );
      }
    }

    if (providerBeingIntegrated === 'BitBucket') {
      try {
        const clientKey = blueprintsStore.getBitbucketClientKey();
        trackVcsIntegrationSuccess('BitBucket');
        setGitProviderAndResetVCSData('BitBucket');

        await fetchRepositories('BitBucket', clientKey);
      } catch (e) {
        onVcsIntegrationFailure('BitBucket');
      }
    }
    finishVcsIntegration();
  }, [
    providerBeingIntegrated,
    finishVcsIntegration,
    blueprintsStore,
    setGitProviderAndResetVCSData,
    fetchRepositories,
    onVcsIntegrationFailure,
    generateGitAuthToken,
    setValue
  ]);

  const onVcsIntegrationBlocked = useCallback(
    () => onVcsIntegrationFailure(providerBeingIntegrated!, 'blocked'),
    [onVcsIntegrationFailure, providerBeingIntegrated]
  );

  const onClickGitProvider = useCallback(
    async (type: GitProviderTypes) => {
      if (!providersSupportingRepositoryIntegration.has(type)) {
        setGitProviderAndResetVCSData(type);
        trackVcsIntegrationSuccess(type);
      } else {
        setIsFormBlocked(true);
        trackVcsEvent({ eventName: EventNames.VCS_INTEGRATION_ATTEMPT, eventProperties: { provider: type } });

        const shouldStartIntegrationForVcs: Record<GitIntegratedVcsProviderTypes, () => Promise<boolean>> = {
          GitHub: async () => isEmpty(await blueprintsStore.getGithubRepositories()),
          GitLab: async () => isEmpty(await getTokens({ tokenType: TokenTypes.GitLab })),
          AzureDevOps: async () => isEmpty(await getTokens({ tokenType: TokenTypes.AzureDevOps })),
          BitBucket: async () => isEmpty(await blueprintsStore.getBitbucketRepositories())
        };

        if (await shouldStartIntegrationForVcs[type as GitIntegratedVcsProviderTypes]()) {
          startVcsIntegration(type);
        } else setGitProviderAndResetVCSData(type);
      }
    },
    [setGitProviderAndResetVCSData, blueprintsStore, getTokens, startVcsIntegration]
  );

  return {
    gitProvider,
    isFormBlocked,
    isLoadingRepositories,
    repositories,
    providerBeingIntegrated,
    startVcsIntegration,
    onVcsIntegrationSuccess,
    onVcsIntegrationBlocked,
    onClickGitProvider
  };
};
