import { Select as AntdSelect } from 'antd';
import type { LabeledValue as ILabeledValue, RefSelectProps, SelectProps } from 'antd/lib/select';
import { CaretDownOutlined } from '@ant-design/icons';
import Loader from 'components/common/loader';
import React from 'react';
import styled from 'types/theme.types';
import Divider from 'components/common/divider';
import omit from 'lodash/omit';

export interface CustomSelectProps extends SelectProps<any, any> {
  children?: React.ReactNode;
  customActions?: React.ReactNode;
  customNotFoundContent?: React.ReactNode;
  withDivider?: boolean;
}

export type LabeledValue = ILabeledValue;

export const SelectOption = AntdSelect.Option;
export const SelectOptGroup = AntdSelect.OptGroup;

const Select = React.forwardRef<RefSelectProps, CustomSelectProps>(
  ({ style, loading, children, customActions, withDivider, customNotFoundContent, disabled, ...props }, ref) => {
    const hasCustomActions = !!customActions;
    const dropdownRender = (menuNode: React.ReactNode) => {
      return (
        <div>
          {menuNode}
          {withDivider && <Divider margin="10px 0px" />}
          {hasCustomActions && <CustomActionsContainer>{customActions}</CustomActionsContainer>}
        </div>
      );
    };

    return (
      <StyledSelect
        notFoundContent={customNotFoundContent}
        style={{ width: '100%', ...style }}
        dropdownRender={dropdownRender}
        popupMatchSelectWidth={false}
        disabled={disabled}
        suffixIcon={<Caret disabled={disabled} />}
        ref={ref}
        {...omit(props, ['hideError'])}>
        {loading ? (
          <SelectOption value="LOADING" disabled>
            <Loader />
          </SelectOption>
        ) : (
          children
        )}
      </StyledSelect>
    );
  }
);

Select.displayName = 'Select';

const CustomActionsContainer = styled.div`
  padding: 8px 30px;
  cursor: pointer;
`;

const Caret = styled(CaretDownOutlined)<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) => (disabled ? theme.nonEditableBorderGray : theme.primaryBlack)} !important;
  pointer-events: none !important;
`;

const StyledSelect = styled(AntdSelect)<any>`
  font-size: 12px !important;
  font-weight: 500 !important;
  color: ${({ theme }) => theme.primaryBlack} !important;
  &.ant-select-disabled .ant-select-selector {
    border: 1px solid ${({ theme }) => theme.lightGray};
  }
`;

export default Select;
