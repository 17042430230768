import type { OrganizationApi } from '@env0/organization-service/api';
import { useQuery } from '@tanstack/react-query';
import useApiClient from 'hooks/use-api-client';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';

export const useGetWebhookSecret = (webhookType: OrganizationApi.GetWebhookSecret.WebhookType) => {
  const orgId = useCurrentOrganizationId();
  const apiClient = useApiClient();

  return useQuery({
    queryKey: [orgId, 'webhook-secret', { webhookType }],
    queryFn: () => apiClient.organizations.getWebhookSecret(orgId, webhookType),
    enabled: Boolean(webhookType)
  });
};
