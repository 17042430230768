import React, { useCallback, useMemo } from 'react';
import TextInput, { type TextInputProps } from 'components/common/input-components/text-input';
import {
  isSecretReference,
  SecretReferencePrefixes,
  getSecretReferenceType,
  getSecretReferencePrefixForType,
  getSecretReferenceId
} from '@env0/common-secret/secret-reference';

interface SecretReferenceInputProps extends TextInputProps {
  value: string;
}

const SecretReferenceInput: React.FC<SecretReferenceInputProps> = ({ value, onChange, ...restProps }) => {
  const valueToDisplay = getSecretReferenceId(value);
  const addonValues = useMemo(() => getAddonValues(value), [value]);
  const onChangeWithCompoundValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const compoundValue = addonValues?.addonBefore + value + addonValues?.addonAfter;

      onChange?.({ target: { value: compoundValue } } as React.ChangeEvent<HTMLInputElement>);
    },
    [addonValues?.addonAfter, addonValues?.addonBefore, onChange]
  );

  return <TextInput value={valueToDisplay} {...addonValues} {...restProps} onChange={onChangeWithCompoundValue} />;
};

const getAddonValues = (value: string): { addonBefore: string; addonAfter: string; addonColor: string } | undefined => {
  if (!value || !isSecretReference(value)) return;

  const secretManagerPrefix = getSecretReferencePrefixForType(getSecretReferenceType(value)!);
  return {
    addonAfter: '}',
    addonBefore: `\${${secretManagerPrefix}:`,
    addonColor: secretManagerToFontColor[secretManagerPrefix]
  };
};

const secretManagerToFontColor: Record<SecretReferencePrefixes, string> = {
  [SecretReferencePrefixes.AWS]: '#FF9900',
  [SecretReferencePrefixes.AZURE]: '#0080FF',
  [SecretReferencePrefixes.GCP]: '#34A853',
  [SecretReferencePrefixes.VAULT]: '#FFCF25',
  [SecretReferencePrefixes.OCI]: '#BF0202'
};

export default SecretReferenceInput;
