import type React from 'react';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import useStores from 'hooks/use-stores.hooks';
import { useParams } from 'react-router-dom';
import isString from 'lodash/isString';
import flow from 'lodash/flow';
import keyBy from 'lodash/keyBy';
import { useCuratedProjects } from 'stores/rq/projects';
import { getIdsOfProjectHierarchy, getRedirectUrlForProject, isSubProject } from 'utils/sub-project.utils';
import { isSubEnvironment } from 'utils/environment.utils';
import { insertAt } from 'utils/array.utils';
import { useGetBlueprints } from 'stores/rq/blueprints';
import { useGetModule } from 'stores/rq/modules';

export type BreadcrumbType = {
  name: string | React.ReactNode;
  to?: string;
};
export type BreadcrumbOptions = Record<BreadcrumbOptionsKeys, BreadcrumbType>;
export type BreadcrumbSelector = (options: BreadcrumbOptions) => (BreadcrumbType | string)[];
export type BreadcrumbOptionsKeys =
  | 'organizationName'
  | 'homepage'
  | 'projects'
  | 'projectName'
  | 'projectTemplates'
  | 'projectEnvironments'
  | 'projectSubProjects'
  | 'projectCredentials'
  | 'environmentName'
  | 'templateName'
  | 'deployments'
  | 'deploymentId'
  | 'organizationTemplates'
  | 'teams'
  | 'teamName'
  | 'roles'
  | 'states'
  | 'stateVersionId'
  | 'dashboards'
  | 'roleId'
  | 'registry'
  | 'registryActiveMenu'
  | 'module'
  | 'modules'
  | 'providers'
  | 'moduleTest'
  | 'cloud'
  | 'cloudTab'
  | 'cloudSettings'
  | 'cloudConfiguration'
  | 'environmentDiscovery';

const generateName = (store: Record<string, { name: string }>, id?: string, defaultValue?: string) =>
  id && store[id] ? store[id].name : defaultValue ?? id;

const useBreadcrumbs = <Selector extends BreadcrumbSelector | undefined>(
  selector: Selector
): Selector extends BreadcrumbSelector ? BreadcrumbType[] : null => {
  const intl = useIntl();
  const organizationUrl = '/';
  const projectsPageUrl = '/projects';

  const params = useParams();
  const {
    projectId,
    environmentId,
    templateId,
    deploymentLogId,
    teamId,
    stateVersionId,
    roleId,
    activeMenu,
    moduleId,
    moduleTestId,
    cloudConfigurationParam
  } = params;
  const { environmentsStore, organizationsStore, teamsStore } = useStores();
  const { organizations, currentOrganizationName } = organizationsStore;
  const { environments } = environmentsStore;
  const organizationId = organizationsStore.currentOrganizationId as string;
  const { projects } = useCuratedProjects({ enabled: Boolean(projectId) });
  const projectName = generateName(projects, projectId, '');
  const organizationName = generateName(organizations, organizationId, currentOrganizationName);
  const environmentName = generateName(environments, environmentId);
  const { data: blueprints } = useGetBlueprints({ enabled: Boolean(templateId) });
  const { data: module } = useGetModule(moduleId);
  const blueprintsMap = keyBy(blueprints, 'id');
  const templateName = generateName(blueprintsMap, templateId);
  const teamName = generateName(teamsStore.teams, teamId);

  const addSubProjectHierarchy = useCallback(
    (breadcrumbs: BreadcrumbType[]) => {
      if (!projectId || !isSubProject(projects[projectId])) return breadcrumbs;
      const projectHierarchyIds = getIdsOfProjectHierarchy(projects[projectId!]).filter(id => id !== projectId);
      const buildAncestorProjectBreadcrumbs = (id: string) => {
        const projectName = generateName(projects, id, '');
        const canViewAncestorProject = projects[id].isReadable;
        const parentProjectURL = getRedirectUrlForProject(projects[id]);
        return { name: projectName, to: canViewAncestorProject ? parentProjectURL : undefined };
      };
      const projectHierarchyBreadcrumbs = projectHierarchyIds.map(buildAncestorProjectBreadcrumbs);
      const insertPoint = breadcrumbs.findIndex(breadcrumb => breadcrumb.name === projectName);
      return insertAt(breadcrumbs, insertPoint, ...projectHierarchyBreadcrumbs);
    },
    [projectId, projectName, projects]
  );

  const addWorkflowParent = useCallback(
    (breadcrumbs: BreadcrumbType[]) => {
      if (!environmentId || !isSubEnvironment(environments[environmentId])) return breadcrumbs;
      const workflowId = environments[environmentId!].workflowEnvironmentId!;
      const workflow = environments[workflowId];
      if (!workflow) {
        // Async loading of the workflow environment when navigating on the sub-env when the workflow
        // can't be found in the store already. This can happen if you access the sub-env page without
        // having gone through the workflow page first. This async loading guarantees that the breadcrumbs
        // are consistent, no matter the navigation path and the state of the store.
        environmentsStore.getEnvironment(workflowId);
        return breadcrumbs;
      }
      const breadcrumb = {
        name: generateName(environments, workflow.id),
        to: `${organizationUrl}p/${projectId}/environments/${workflow.id}`
      };
      const insertPoint = breadcrumbs.findIndex(breadcrumb => breadcrumb.name === environmentName);
      return insertAt(breadcrumbs, insertPoint, breadcrumb);
    },
    [projectId, environmentId, environmentName, environmentsStore, environments]
  );

  return useMemo(() => {
    if (!selector) {
      return null as any;
    }

    const projectEnvironmentsUrl = `${organizationUrl}p/${projectId}/environments`;
    const projectSubProjectsUrl = `${organizationUrl}p/${projectId}/sub-projects`;
    const projectTemplatesUrl = `${organizationUrl}p/${projectId}/templates`;
    const projectDeploymentsUrl = `${projectEnvironmentsUrl}/${environmentId}?tabname=deployments`;
    const projectStatesUrl = `${projectEnvironmentsUrl}/${environmentId}?tabname=states`;
    const projectEnvironmentDiscoveryUrl = `${organizationUrl}p/${projectId}/settings/environment-discovery`;
    const organizationTemplateUrl = `${organizationUrl}templates`;
    const organizationTemplateSettingsUrl = `${organizationTemplateUrl}/${templateId}/settings`;
    const teamsUrl = `${organizationUrl}organizations/${organizationId}/teams`;
    const teamUrl = `${teamsUrl}/${teamId}`;
    const rolesUrl = `${organizationUrl}organizations/${organizationId}/roles`;
    const dashboardsUrl = `${organizationUrl}dashboards`;

    const f = (id: string) => intl.formatMessage({ id });

    const breadcrumbOptions: BreadcrumbOptions = {
      organizationName: { name: organizationName, to: organizationUrl },
      homepage: { name: f('homepage'), to: organizationUrl },
      projects: { name: f('projects'), to: projectsPageUrl },
      projectName: { name: projectName, to: projectEnvironmentsUrl },
      projectTemplates: { name: f('templates'), to: projectTemplatesUrl },
      projectEnvironments: { name: f('navigation.environments.breadcrumb'), to: projectEnvironmentsUrl },
      projectSubProjects: { name: f('navigation.project.sub-projects.breadcrumb'), to: projectSubProjectsUrl },
      projectCredentials: { name: f('navigation.deployment-credentials.breadcrumb'), to: projectEnvironmentsUrl },
      templateName: { name: templateName, to: organizationTemplateSettingsUrl },
      environmentName: { name: environmentName, to: `${projectEnvironmentsUrl}/${environmentId}` },
      deployments: { name: f('navigation.environment.deployment.log.breadcrumb'), to: projectDeploymentsUrl },
      deploymentId: { name: deploymentLogId },
      organizationTemplates: { name: f('templates'), to: organizationTemplateUrl },
      teams: { name: f('teams'), to: teamsUrl },
      teamName: { name: teamName, to: teamUrl },
      roles: { name: f('roles'), to: rolesUrl },
      states: { name: f('states'), to: projectStatesUrl },
      stateVersionId: { name: stateVersionId },
      dashboards: { name: f('dashboards'), to: dashboardsUrl },
      roleId: { name: roleId },
      registry: { name: f('registry'), to: `${organizationUrl}registry` },
      modules: { name: f('modules'), to: `${organizationUrl}registry/modules` },
      providers: { name: f('providers'), to: `${organizationUrl}registry/providers` },
      module: { name: module?.moduleName, to: `${organizationUrl}registry/modules/${module?.id}?tabname=tests` },
      moduleTest: {
        name: moduleTestId,
        to: `${organizationUrl}registry/modules/${module?.id}/tests/${moduleTestId}`
      },
      registryActiveMenu: {
        name: f(activeMenu ?? 'modules'),
        to: `${organizationUrl}registry/${activeMenu ?? 'modules'}`
      },
      cloud: { name: f('cloud'), to: `${organizationUrl}cloud` },
      cloudSettings: { name: f('settings'), to: `${organizationUrl}cloud/settings` },
      cloudTab: {
        name: f(activeMenu ?? 'insights'),
        to: `${organizationUrl}cloud/${activeMenu ?? 'insights'}`
      },
      cloudConfiguration: {
        name: f(cloudConfigurationParam === 'new' ? 'cloud.new' : 'cloud.edit')
      },
      environmentDiscovery: { name: f('settings'), to: projectEnvironmentDiscoveryUrl }
    };

    const castBreadcrumbs = (breadcrumbs: (BreadcrumbType | string)[]) =>
      breadcrumbs.map(b => (isString(b) ? { name: f(b) } : b));

    return flow(selector, castBreadcrumbs, addSubProjectHierarchy, addWorkflowParent)(breadcrumbOptions);
  }, [
    intl,
    selector,
    organizationName,
    projectName,
    templateName,
    environmentName,
    deploymentLogId,
    environmentId,
    projectId,
    templateId,
    organizationId,
    teamId,
    teamName,
    stateVersionId,
    roleId,
    addSubProjectHierarchy,
    addWorkflowParent,
    activeMenu,
    module,
    moduleTestId,
    cloudConfigurationParam,
    projectsPageUrl
  ]);
};

export default useBreadcrumbs;
