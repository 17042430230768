import React from 'react';
import Favicon from 'react-favicon';
import type { EnvironmentApi } from '@env0/environment-service/api';

export interface FaviconProps {
  environmentStatus?: EnvironmentApi.Environment['status'];
  deploymentStatus?: EnvironmentApi.DeploymentLogStatus;
}

const favIconBasePath = '/favicons';

export const DEFAULT_FAV_ICON = favIconBasePath + '/favicon.ico';
export const IN_PROGRESS_FAV_ICON = favIconBasePath + '/in_progress.png';
export const INACTIVE_FAV_ICON = favIconBasePath + '/inactive.png';
export const WAITING_FOR_USER_FAV_ICON = favIconBasePath + '/waiting_for_user.png';
export const FAILED_FAV_ICON = favIconBasePath + '/failed.png';

const FaviconSwitcher = ({ environmentStatus, deploymentStatus }: FaviconProps) => {
  const favIcon = getFavIconPerStatus(environmentStatus, deploymentStatus);
  return <Favicon url={favIcon} />;
};

const getFavIconPerStatus = (
  environmentStatus?: EnvironmentApi.Environment['status'],
  deploymentStatus?: EnvironmentApi.DeploymentLogStatus
) => {
  if (environmentStatus) return getFavIconsFromEnvironment(environmentStatus);
  else if (deploymentStatus) return getFavIconsFromDeployment(deploymentStatus);
  else return DEFAULT_FAV_ICON;
};

const getFavIconsFromEnvironment = (environmentStatus?: EnvironmentApi.Environment['status']) => {
  switch (environmentStatus) {
    case 'DEPLOY_IN_PROGRESS':
    case 'DESTROY_IN_PROGRESS':
    case 'ABORTING':
      return IN_PROGRESS_FAV_ICON;

    case 'FAILED':
    case 'TIMEOUT':
    case 'ABORTED':
      return FAILED_FAV_ICON;

    case 'INACTIVE':
      return INACTIVE_FAV_ICON;

    case 'WAITING_FOR_USER':
      return WAITING_FOR_USER_FAV_ICON;

    case 'ACTIVE':
    case 'CREATED':
      return DEFAULT_FAV_ICON;

    default:
      return DEFAULT_FAV_ICON;
  }
};

const getFavIconsFromDeployment = (deploymentStatus?: EnvironmentApi.DeploymentLogStatus) => {
  switch (deploymentStatus) {
    case 'ABORTING':
    case 'IN_PROGRESS':
      return IN_PROGRESS_FAV_ICON;

    case 'INTERNAL_FAILURE':
    case 'TIMEOUT':
    case 'FAILURE':
    case 'ABORTED':
    case 'CANCELLED':
      return FAILED_FAV_ICON;

    case 'QUEUED':
    case 'SUCCESS':
      return DEFAULT_FAV_ICON;

    default:
      return DEFAULT_FAV_ICON;
  }
};

export default FaviconSwitcher;
