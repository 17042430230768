import { BadRequest } from 'http-errors';

export enum SecretReferencePrefixes {
  AWS = 'ssm',
  GCP = 'gcp',
  AZURE = 'azure',
  VAULT = 'vault',
  OCI = 'oci'
}

type SecretReferenceProvider = keyof typeof SecretReferencePrefixes;

const SECRET_REF_POSTFIX = '}';

const getFullPrefix = (secretType: string) => `\${${secretType}:`;
const startsWithPrefix = (reference: string | undefined, prefix: string) =>
  reference?.startsWith(getFullPrefix(prefix));

export const getSecretReferencePrefixForType = (type: SecretReferenceProvider) => SecretReferencePrefixes[type];

export const getSecretReferenceType = (reference: string): SecretReferenceProvider | undefined =>
  (Object.keys(SecretReferencePrefixes) as SecretReferenceProvider[]).find(refType =>
    startsWithPrefix(reference, SecretReferencePrefixes[refType])
  );

export const isSecretReference = (value: string | undefined) =>
  Object.values(SecretReferencePrefixes).some(
    prefix => startsWithPrefix(value, prefix) && value?.endsWith(SECRET_REF_POSTFIX)
  );

export const getSecretReferenceId = (reference: string) => {
  if (!isSecretReference(reference)) throw new BadRequest(`${reference} is not a valid secret reference`);
  const refType = getSecretReferenceType(reference) as SecretReferenceProvider;

  return reference.slice(getFullPrefix(SecretReferencePrefixes[refType]).length, -SECRET_REF_POSTFIX.length);
};

export const createSecretReference = (prefix: SecretReferencePrefixes, id: string) =>
  `${getFullPrefix(prefix)}${id}${SECRET_REF_POSTFIX}`;
