import React from 'react';
import { observer } from 'mobx-react';
import { useArchiveProject } from 'stores/rq/projects';
import type { Project } from 'types/api.types';
import { Bold } from 'components/common/modal';
import DeletePopup from 'components/common/delete-popup';

interface ArchiveProjectButtonProps {
  isSubmitting: boolean;
  project: Project;
}

export const ArchiveProjectButton: React.FunctionComponent<ArchiveProjectButtonProps> = observer(
  function ArchiveProjectButton({ isSubmitting, project }) {
    const { mutateAsync: handleArchive } = useArchiveProject();

    return (
      <DeletePopup
        data-e2e="project-archive-modal"
        disabled={isSubmitting || project.isArchived}
        onDelete={() => handleArchive(project.id)}
        messagePrefix={'projects.settings.archive'}
        popupTitleVariables={{ name: <Bold>{project.name}</Bold> }}
      />
    );
  }
);
