import type { ReactElement } from 'react';
import React from 'react';
import styled from 'types/theme.types';
import { Menu } from 'antd';

export interface MenuProps {
  setActiveMenu: (key: string) => void;
  activeMenu: string;
  menus: { key: string; value: ReactElement }[];
  children: React.ReactNode;
}

const TabsMenu: React.FunctionComponent<MenuProps> = ({ activeMenu, setActiveMenu, menus, children }) => {
  return (
    <Container data-e2e="tabs-menu">
      <StyledMenu
        onClick={event => setActiveMenu(event.key.toString())}
        selectedKeys={[activeMenu]}
        mode="horizontal"
        items={menus.map(menu => ({
          key: menu.key,
          label: menu.value,
          'data-e2e': `menu-${menu.key}`
        }))}
      />

      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const ContentContainer = styled.div`
  padding: 18px 0;
`;

const StyledMenu = styled(Menu)`
  background: transparent !important;
  border-bottom: 2px solid ${({ theme }) => theme.secondaryGray} !important;
  height: 35px;
  flex: 1;

  .ant-menu-item {
    color: ${({ theme }) => (theme.mode === 'dark' ? theme.primaryBlack : theme.secondaryDisabledGray)} !important;
    border-bottom-width: 3px !important;
    border-bottom-color: transparent;
    font-size: 15px;
    font-weight: 600;
    line-height: 31px;
    padding: 0 22px !important;
    letter-spacing: -0.08px;
    text-transform: uppercase;
    z-index: 2;

    &-selected {
      color: ${({ theme }) => theme.secondaryBlue} !important;
      border-bottom-width: 3px !important;
      border-bottom-color: ${({ theme }) => theme.primaryGreen};
    }
  }
`;

export default TabsMenu;
