import React from 'react';
import styled from 'types/theme.types';
import { SwitcherContainer } from 'components/layout/topbar/switcher/switcher-container';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import useStores from 'hooks/use-stores.hooks';

const UserSwitcher: React.FunctionComponent = () => {
  const { authStore } = useStores();
  return (
    <SwitcherContainer>
      <Link to="/personal-settings">
        <MenuItem>
          <FormattedMessage id="personal.settings.menu.personal-settings" />
        </MenuItem>
      </Link>
      <MenuItem onClick={() => authStore.logout()} data-e2e="logout-link">
        <FormattedMessage id="navigation.logout" />
      </MenuItem>
    </SwitcherContainer>
  );
};

const MenuItem = styled.div`
  cursor: pointer;
  padding: 14px 18px;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.primaryBlack} !important;
  background: ${({ theme }) => theme.primaryWhite};

  &:hover {
    background: ${({ theme }) => theme.menuGray};
  }
`;

export default UserSwitcher;
