import type { ConfigurationProperty, VariablesPolicies } from 'types/api.types';
import { ConfigurationScope, ConfigurationValueType } from 'types/api.types';
import isEqual from 'lodash/isEqual';
import type { PartialJSONSchema7 } from '@env0/configuration-service/api';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import some from 'lodash/some';
import { type ConfigurationPropertyStore, SENSITIVE_MASK } from 'stores/mobx/configuration-property.store';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import type { Environment } from 'types/api.types';
import { isSecretReference } from '@env0/common-secret/secret-reference';

// TODO: this file contains a mix of configuration-business-logic and utils for the frontend state control
// They should be split up and the business-logic should be shared with the backend which also does many of these validations and logic.

type ValidationResult = {
  error?: string;
  isValid: boolean;
};

export const ENV0_CUSTOM_BACKEND_VAR = 'ENV0_CUSTOM_BACKEND';

const setConfigOptions = (config: ConfigurationProperty, newOptions: string[]): ConfigurationProperty => ({
  ...config,
  schema: {
    ...config.schema,
    enum: newOptions
  }
});

export const getConfigurationOptions = (config: ConfigurationProperty): string[] | undefined => {
  const parentOptions = config.overwrites?.schema?.enum;
  const childOptions = config.schema?.enum;

  return parentOptions && childOptions ? parentOptions : childOptions;
};

export const addConfigurationOption = (config: ConfigurationProperty, addedOption: string): ConfigurationProperty => {
  const existingOptions = getConfigurationOptions(config) ?? [];
  return setConfigOptions(config, [...existingOptions, addedOption]);
};

export const removeConfigurationOption = (
  config: ConfigurationProperty,
  removedOption: string
): ConfigurationProperty => {
  const existingOptions = getConfigurationOptions(config) ?? [];
  return setConfigOptions(
    config,
    existingOptions.filter(option => option !== removedOption)
  );
};

export const getSchemaValue = (valueType: ConfigurationValueType): PartialJSONSchema7 => {
  switch (valueType) {
    case ConfigurationValueType.FREE_TEXT:
      return { type: 'string' };
    case ConfigurationValueType.DROPDOWN_LIST:
      return { type: 'string', enum: [] };
    case ConfigurationValueType.ENVIRONMENT_OUTPUT:
      return { type: 'string', format: 'ENVIRONMENT_OUTPUT' };
    case ConfigurationValueType.HCL_FORMAT:
      return { format: 'HCL' };
    case ConfigurationValueType.JSON_FORMAT:
      return { format: 'JSON' };
  }
};

export const validateJsonFormat = (content: string): ValidationResult => {
  const result: ValidationResult = { isValid: false };
  if (content.length === 0) {
    result.isValid = true;
  } else {
    try {
      JSON.parse(content);
      result.isValid = true;
    } catch (e: any) {
      result.error = `failed to parse content as JSON ${e.message}`;
    }
  }
  return result;
};

export function validateConfigurationProperty(
  data: ConfigurationProperty,
  editScope: ConfigurationScope,
  isExistsSensitiveAndSensitiveValueNotChanged: boolean = false,
  { allowSaveSensitive, allowSsmReference }: VariablesPolicies
) {
  if (isEmpty(data.name?.trim())) {
    return { message: 'settings.variables.no.name.is.not.allowed', appearsUnder: 'name' };
  }

  if (isArray(data.schema?.enum) && isEmpty(data.schema?.enum)) {
    return { message: 'settings.variables.list.empty', appearsUnder: 'name' };
  }

  if (data.schema?.format === 'JSON' && !(!data.overwrites && data.isSensitive && data.value === SENSITIVE_MASK)) {
    const jsonValidationResult = validateJsonFormat(data.value!);
    if (!jsonValidationResult.isValid) {
      return { message: 'settings.variables.invalid.JSON', appearsUnder: 'value' };
    }
  }

  if (data.isSensitive && !allowSaveSensitive) {
    return { message: 'settings.variables.policies.saving.secret.not.allowed', appearsUnder: 'value' };
  }

  if (data.isRequired && data.isReadonly && isEmpty(data.value)) {
    return { message: 'settings.variables.missing.required-readonly.variable', appearsUnder: 'value' };
  }

  if (
    (editScope === ConfigurationScope.ENVIRONMENT || editScope === ConfigurationScope.WORKFLOW) &&
    isEmpty(data.value)
  ) {
    if (data.isSensitive) {
      return { message: 'settings.variables.missing.sensitive.variable', appearsUnder: 'value' };
    }

    if (data.isRequired) {
      return { message: 'settings.variables.missing.required.variable', appearsUnder: 'value' };
    }
  }

  if (!isEmpty(data.regex)) {
    let regex: RegExp;
    try {
      regex = new RegExp(data.regex!);
    } catch (ignore) {
      return { message: 'settings.variables.advanced-options.regex.invalid', appearsUnder: 'regex' };
    }

    const valueDoesntMatchRegex = !data.value.match(regex);
    if (
      valueDoesntMatchRegex &&
      !isExistsSensitiveAndSensitiveValueNotChanged &&
      (!isEmpty(data.value) ||
        editScope === ConfigurationScope.WORKFLOW ||
        editScope === ConfigurationScope.ENVIRONMENT ||
        data.isReadonly)
    ) {
      return { message: 'settings.variables.advanced-options.regex.no.match', appearsUnder: 'value' };
    }
  }

  if (!allowSsmReference.allowed && isSecretReference(data.value)) {
    return { message: allowSsmReference.message ?? 'error', appearsUnder: 'value' };
  }

  return;
}

export const getErrorForField = (data: Partial<ConfigurationProperty>, fieldName: string) => {
  return data.error?.appearsUnder === fieldName && data.error?.message ? data.error?.message : null;
};

const FIELDS_TO_COMPARE: Array<keyof ConfigurationProperty> = [
  'name',
  'value',
  'isSensitive',
  'isRequired',
  'isReadonly',
  'regex',
  'schema',
  'description'
];
export function hasConfigurationChanged(
  oldConfiguration: ConfigurationProperty,
  newConfiguration: ConfigurationProperty
) {
  return some(FIELDS_TO_COMPARE, field => !isEqual(oldConfiguration?.[field], newConfiguration?.[field]));
}

export const createOnChange =
  (data: ConfigurationPropertyStore, key: keyof ConfigurationPropertyStore) => (event: any) => {
    const checkBoxValues = ['isSensitive', 'isRequired', 'isReadonly'];
    const newData = {
      [key]: checkBoxValues.includes(key) ? event.target.checked : event.target.value
    };

    data.update(newData);
  };

export function assignDefaultValues(props: Partial<ConfigurationProperty>) {
  return {
    name: '',
    value: '',
    description: '',
    isSensitive: false,
    isRequired: false,
    isReadonly: false,
    regex: '',
    ...props,
    overwrites: props.overwrites
      ? {
          isSensitive: false,
          isRequired: false,
          isReadonly: false,
          regex: '',
          value: '',
          ...props.overwrites
        }
      : undefined
  };
}

export function getWorkflowEnvironmentIdScope(
  templateType: BlueprintApi.BlueprintType,
  environment?: Environment
): string | undefined {
  const isWorkflowParent = templateType === 'workflow';
  return isWorkflowParent ? environment?.id! : environment?.workflowEnvironmentId!;
}
