import { apiClient } from 'services/api-client';
import type { NavigateFunction } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import throttle from 'lodash/throttle';
import { useHasPermission } from 'hooks/use-has-permission';
import { useGetUserPermissions } from 'stores/rq/user-permissions';
import type { Environment, Project } from 'types/api.types';
import { useAsyncPromise } from 'hooks/use-async-promise';
import { useCuratedProjects } from 'stores/rq/projects';
import { validCharactersRegexp } from 'schemas/deploy-schema';

type UseEnvironmentSearchActionsProps = {
  searchTerm: string;
  enabled: boolean;
};

const ENVIRONMENT_SEARCH_RESULTS = 20;
const TYPING_DELAY_BETWEEN_REFETCH = 200;

const throttledFetchFilteredEnvironments = throttle(async (searchTerm: string, currentOrganizationId: string) => {
  if (!currentOrganizationId || !validCharactersRegexp.test(searchTerm)) {
    return [];
  }

  const { data: environments } = await apiClient.environments.getAll({
    organizationId: currentOrganizationId,
    limit: ENVIRONMENT_SEARCH_RESULTS,
    searchText: searchTerm,
    onlyMy: false,
    isActive: true
  });
  return environments;
}, TYPING_DELAY_BETWEEN_REFETCH);

const mapEnvironmentsToActions = (
  environments: Environment[],
  projects: Record<string, Project>,
  navigate: NavigateFunction
) =>
  environments.flatMap(environment => [
    {
      id: environment.projectId,
      name: projects[environment.projectId]?.name,
      section: 'Environments'
    },
    {
      id: environment.id,
      keywords: `${environment.id} ${environment.name} ${environment.workspaceName}`,
      name: environment.name,
      section: 'Environments',
      parent: environment.projectId,
      perform: () => navigate(`/p/${environment.projectId}/environments/${environment.id}`)
    }
  ]);

const useHasEnvironmentViewAssignment = () => {
  const { isPlaceholderData: isLoading, data: userPermissionsByScope } = useGetUserPermissions();

  const isAuthorized =
    userPermissionsByScope?.environments ??
    Object.values(userPermissionsByScope?.environments ?? {}).some(env => env.permissions.includes('VIEW_ENVIRONMENT'));

  return { isAuthorized, isLoading };
};

export const useEnvironmentSearchActions = ({ searchTerm, enabled }: UseEnvironmentSearchActionsProps) => {
  const navigate = useNavigate();
  const currentOrganizationId = useCurrentOrganizationId();
  const { projects, isLoading: isLoadingProjects } = useCuratedProjects();

  const { isAuthorized, isLoading } = useHasPermission(['VIEW_ENVIRONMENT', 'VIEW_PROJECT'], {
    permissionOperator: 'OR'
  });
  const { isAuthorized: isAssignedToAnyEnvironment, isLoading: isLoadingEnvAssignments } =
    useHasEnvironmentViewAssignment();

  const [, filteredEnvironmentActions] = useAsyncPromise(async () => {
    if (
      !enabled ||
      !(isAuthorized || isAssignedToAnyEnvironment) ||
      isLoading ||
      isLoadingProjects ||
      isLoadingEnvAssignments
    ) {
      return [];
    }

    return throttledFetchFilteredEnvironments(searchTerm, currentOrganizationId)?.then(environments =>
      mapEnvironmentsToActions(environments, projects, navigate)
    );
  }, [
    enabled,
    projects,
    isAuthorized,
    isAssignedToAnyEnvironment,
    isLoading,
    isLoadingProjects,
    isLoadingEnvAssignments,
    searchTerm,
    currentOrganizationId,
    navigate
  ]);

  return filteredEnvironmentActions ?? [];
};
