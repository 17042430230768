import React from 'react';
import Select, { SelectOption } from 'components/common/input-components/select';
import styled from 'types/theme.types';

import { BranchesOutlined, EditOutlined, WarningOutlined } from '@ant-design/icons';
import Button from 'components/common/button';
import { FormattedMessage } from 'react-intl';
import { useModal } from 'hooks/modal.hooks';
import { useGetVcsRepositories } from 'stores/rq/vcs-connections';
import { VcsConnectionsModal } from 'components/organizations/agents/vcs-connections-modal';
import type { VcsApi } from '@env0/vcs-service/api';

export interface VcsRepositorySelectProps {
  value: string;
  onChange: (value: string) => void;
  vcsType: VcsApi.VcsType;
}

export const VcsRepositorySelector: React.FC<VcsRepositorySelectProps> = ({ value, onChange, vcsType }) => {
  const { data, isFetching } = useGetVcsRepositories(vcsType);

  const { hideModal, showModal, isModalOpen } = useModal();

  return (
    <>
      <Select
        showSearch={true}
        value={value}
        onChange={onChange}
        loading={isFetching}
        listHeight={200}
        placement="bottomLeft"
        data-e2e="vcs-repository-selector"
        customActions={
          <Button onClick={showModal} type="primary" size="small">
            <FormattedMessage id="vcs.add-button" />
          </Button>
        }>
        {data?.repositories?.map(repository => {
          return (
            <SelectOption key={repository.httpsUrl} value={repository.httpsUrl} data={repository}>
              <SelectItemContainer>
                {repository.isManualInput ? <ManualIcon /> : <VcsIcon />}
                {`${repository.name} - ${repository.httpsUrl}`}
              </SelectItemContainer>
            </SelectOption>
          );
        })}
        {data?.errors?.map((error, index) => {
          return (
            <SelectOption key={index} value={index} disabled={true}>
              <SelectItemContainer>
                <ErrorIcon />
                {error}
              </SelectItemContainer>
            </SelectOption>
          );
        })}
      </Select>
      {isModalOpen && <VcsConnectionsModal isModalOpen={true} hideModal={hideModal} vcsType={vcsType} />}
    </>
  );
};

const SelectItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
`;

const ErrorIcon = styled(WarningOutlined)`
  height: 15px;
  width: 15px;
  margin-right: 5px;
  color: red;
`;

const VcsIcon = styled(BranchesOutlined)`
  height: 15px;
  width: 15px;
  margin-right: 5px;
`;

const ManualIcon = styled(EditOutlined)`
  height: 15px;
  width: 15px;
  margin-right: 5px;
  color: #7fa1c5;
`;
