import type { SchemaOf } from 'yup';
import * as Yup from 'yup';
import type { Blueprint, BlueprintSshKey } from 'types/api.types';
import type { Optional } from 'utility-types';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import {
  DEFAULT_ANSIBLE_VERSION,
  DEFAULT_OPENTOFU_VERSION,
  DEFAULT_PULUMI_VERSION,
  DEFAULT_TF_VERSION,
  DEFAULT_TG_VERSION
} from 'components/templates/templates-wizard/settings/versions-list';
import { isSsh, validateGitUrl, validateHelmRepoUrl } from 'components/templates/templates-wizard/url-utils';
import type {
  EnvironmentDetailsValues,
  TemplateSettingsStepCommonValues,
  VCSStepValues
} from 'components/templates/templates-wizard/common/template-wizard.types';
import { getDeploySchema } from 'schemas/deploy-schema';

const sshKeySchema: SchemaOf<BlueprintSshKey> = Yup.object({
  id: Yup.string().required(),
  name: Yup.string().required()
});
const sshKeysSchema = Yup.array().of(sshKeySchema).default([]);

const retryConfigSchema: SchemaOf<Optional<BlueprintApi.RetryDeployOrDestroyConfig>> = Yup.object({
  times: Yup.number(),
  errorRegex: Yup.string()
});

const terraformVersionSchema = Yup.mixed<BlueprintApi.TerraformVersion>().default(DEFAULT_TF_VERSION).nullable(); // nullable for deprecated old templates
const versionSchema = (defaultValue: string) => Yup.string().default(defaultValue);

export const commonSettingsStepSchema: SchemaOf<TemplateSettingsStepCommonValues> = Yup.object({
  description: Yup.string().notRequired(),
  type: Yup.mixed<Blueprint['type']>()
    .oneOf(['opentofu', 'terraform', 'terragrunt', 'pulumi', 'k8s', 'cloudformation', 'workflow', 'helm', 'ansible'])
    .default(null)
    .required(),
  terraformVersion: terraformVersionSchema,
  opentofuVersion: versionSchema(DEFAULT_OPENTOFU_VERSION),
  terragruntVersion: versionSchema(DEFAULT_TG_VERSION),
  terragruntTfBinary: Yup.mixed<BlueprintApi.TerragruntTfBinary>()
    .oneOf(['opentofu', 'terraform'])
    .default('opentofu')
    .required(),
  isTerragruntRunAll: Yup.boolean().notRequired(),
  pulumiVersion: versionSchema(DEFAULT_PULUMI_VERSION),
  ansibleVersion: versionSchema(DEFAULT_ANSIBLE_VERSION),
  sshKeys: sshKeysSchema,
  retry: Yup.object({
    onDeploy: retryConfigSchema.nullable(),
    onDestroy: retryConfigSchema.nullable()
  }).default({ onDeploy: null, onDestroy: null }),
  approvalPoliciesAssignments: Yup.array<BlueprintApi.ApprovalPolicyTemplateWithScope>().default([])
});
export const getVcsStepSchema: (type: BlueprintApi.BlueprintType) => SchemaOf<VCSStepValues> = type =>
  Yup.object({
    repository: Yup.string()
      .required('validation.required')
      .test({
        test: function (value) {
          const { isHelmRepository } = this.parent;
          if (isHelmRepository) {
            if (!validateHelmRepoUrl(value)) {
              return this.createError({ message: `templates.wizard.step.vcs.repository.helm.validation` });
            }
          } else {
            if (!validateGitUrl(value)) {
              return this.createError({ message: `templates.wizard.step.vcs.repository.git.validation` });
            }
          }
          return true;
        }
      })
      .default(''),
    path: Yup.string().default(''),
    helmChartName: Yup.string().when('isHelmRepository', {
      is: true,
      then: Yup.string().required('validation.required'),
      otherwise: Yup.string().notRequired()
    }),
    revision: Yup.string().default(''),
    githubInstallationId: Yup.number().nullable().default(null),
    tokenId: Yup.string().default(''),
    tokenName: Yup.string().default(''),
    isGitLab: Yup.boolean().default(false),
    isAzureDevOps: Yup.boolean().default(false),
    isHelmRepository: Yup.boolean().default(false),
    bitbucketClientKey: Yup.string().nullable().default(null),
    isBitbucketServer: Yup.boolean().default(false),
    isGitLabEnterprise: Yup.boolean().default(false),
    isGitHubEnterprise: Yup.boolean().default(false),
    sshKeys: Yup.mixed().when('repository', {
      is: isSsh,
      then: sshKeysSchema.min(1, 'templates.wizard.step.vcs.ssh.validation'),
      otherwise: sshKeysSchema
    }),
    fileName: type === 'cloudformation' ? Yup.string().default('').required() : Yup.string().default(''),
    vcsConnectionId: Yup.string().nullable()
  }).defined();

export const getEnvironmentDetailsSchema = (
  blueprintType?: BlueprintApi.BlueprintType
): SchemaOf<EnvironmentDetailsValues> =>
  getDeploySchema(blueprintType).omit([
    'blueprintRevision',
    'terragruntWorkingDirectory',
    'templateId',
    'showCDCheckboxes'
  ]);
