import React, { useCallback, useMemo } from 'react';
import styled from 'types/theme.types';
import TextInput from './input-components/text-input';
import type { InputProps } from 'antd/lib/input';
import { SearchOutlined } from '@ant-design/icons';
import Tooltip from 'components/common/tooltip';
import debounce from 'lodash/debounce';
import { isTestNodeEnv } from 'constants/config';
import type { InputRef } from 'antd';

export interface SearchBarProps extends InputProps {
  addSuffix?: boolean;
  placeholder?: string;
  onFilter: (query: string) => void;
  debounceMs?: number;
  hideError?: boolean;
  error?: string | null;
}

export const DEFAULT_DEBOUNCE = isTestNodeEnv ? 5 : 250;

// eslint-disable-next-line react/display-name
const SearchBar: React.FunctionComponent<SearchBarProps> = React.forwardRef<InputRef, SearchBarProps>(
  (
    {
      addSuffix = true,
      onFilter,
      placeholder,
      hideError,
      debounceMs = DEFAULT_DEBOUNCE,
      value,
      error,
      ...otherProps
    }: SearchBarProps,
    ref
  ) => {
    const onFilterWithDebounce = useMemo(() => {
      return debounce(async searchValue => {
        onFilter(searchValue);
      }, debounceMs || 250);
    }, [debounceMs, onFilter]);

    const setSearch = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onFilterWithDebounce(e.target.value);
      },
      [onFilterWithDebounce]
    );

    let textInputSuffix = <SearchOutlined />;

    if (placeholder) {
      textInputSuffix = (
        <Tooltip titleId={placeholder} placement="right">
          {textInputSuffix}
        </Tooltip>
      );
    }

    return (
      <StyledTextInput
        data-e2e="search-bar"
        error={error}
        placeholder={placeholder}
        hideError={!!hideError}
        {...otherProps}
        ref={ref}
        onChange={setSearch}
        suffix={addSuffix ? textInputSuffix : undefined}
      />
    );
  }
);

const StyledTextInput = styled(TextInput)`
  border: 1px solid ${({ theme }) => theme.lightGray} !important;
`;

export default SearchBar;
