import type { Auth0UserProfile } from 'auth0-js';
import { action, observable } from 'mobx';
import BaseService from 'services/base-service';
import type ServiceContainer from 'services/service-container';
import type { ThemeType } from 'types/theme.types';

export class UserStore extends BaseService {
  @observable profile?: Auth0UserProfile;
  @observable appTheme: ThemeType;

  constructor(service: ServiceContainer) {
    super(service);
    this.appTheme = (localStorage.getItem('appTheme') as ThemeType) || 'light';
  }

  @action setProfile(profile: Auth0UserProfile) {
    this.profile = profile;
  }

  @action.bound setAppTheme(appTheme: ThemeType) {
    this.appTheme = appTheme;
    localStorage.setItem('appTheme', appTheme);
  }

  get userId() {
    return this.profile && this.profile.sub;
  }
}
