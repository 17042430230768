import { useNavigate } from 'react-router-dom';
import type { Action } from 'kbar';
import TemplateIcon from 'assets/images/template-icon.svg';
import DocsIcon from 'assets/images/docs-icon.svg';
import VariableIcon from 'assets/images/variables-icon.svg';
import DashboardIcon from 'assets/images/graph-icon.svg';
import ModulesIcon from 'assets/images/terraform-module-icon.svg';
import SettingsIcon from 'assets/images/settings-icon.svg';
import CloudInsightsIcon from 'assets/images/cloud-insights-bw.svg';
import HomepageIcon from 'assets/images/org-homepage-bw.svg';

import capitalize from 'lodash/capitalize';
import { ORGANIZATION_SETTINGS_ROOT_ID } from 'hooks/kbar/use-settings-tabs-actions.hooks';
import { useHasPermissionCallback } from 'hooks/use-has-permission';
import type { RolesApi } from '@env0/role-service/api';
import compact from 'lodash/compact';
import { useMemo } from 'react';

type RBACPermission = RolesApi.RBACPermission;

type Navigate = (path: string) => void;

const section = 'Organization';

const docsAction = {
  id: 'docs',
  name: 'env0 docs',
  keywords: 'docs',
  section: 'docs',
  perform: () => global.open('https://docs.env0.com/'),
  icon: DocsIcon
};

const organizationSettingsBasicAction: Action = {
  id: ORGANIZATION_SETTINGS_ROOT_ID,
  name: 'Settings',
  keywords: 'settings',
  section,
  icon: SettingsIcon
};

const generateCreatableEntityActions = (navigate: Navigate, sectionName: string, icon: Action['icon']): Action[] => {
  const sectionAction: Action = {
    id: `GENERAL_${sectionName.toUpperCase()}_SECTION`,
    name: sectionName,
    keywords: sectionName,
    icon,
    section
  };

  const viewAction: Action = {
    id: `GOTO_${sectionName.toUpperCase()}_PAGE`,
    parent: sectionAction.id,
    name: `Go to ${sectionName}`,
    keywords: `Go to ${sectionName}`,
    perform: () => navigate(`/${sectionName.toLowerCase()}`),
    icon,
    section: sectionName
  };
  const createNewAction: Action = {
    id: `CREATE_NEW_${sectionName.toUpperCase()}`,
    parent: sectionAction.id,
    name: `Create new ${sectionName}`,
    keywords: `Create new ${sectionName}`,
    perform: () => navigate(`/${sectionName.toLowerCase()}/add-new-${sectionName.toLowerCase().slice(0, -1)}`),
    icon,
    section: sectionName
  };
  return [sectionAction, viewAction, createNewAction];
};

const generateTemplatesSectionActions = (navigate: Navigate): Action[] =>
  generateCreatableEntityActions(navigate, 'Templates', TemplateIcon);
const generateModulesSectionActions = (navigate: Navigate): Action[] =>
  generateCreatableEntityActions(navigate, 'Modules', ModulesIcon);

export default function useBasicNavigationActions(): Action[] {
  const navigate = useNavigate();
  const hasPermission = useHasPermissionCallback();

  return useMemo(() => {
    const can = (permission: RolesApi.RBACPermission | RBACPermission[]) => hasPermission(permission).isAuthorized;

    const getOrgAction = (id: string, overrides: Partial<Action>): Action => ({
      id,
      name: capitalize(id),
      keywords: id,
      perform: () => navigate(`/${id}`),
      section,
      ...overrides
    });

    const homepageAction = getOrgAction('home', { icon: HomepageIcon, perform: () => navigate('/') });
    const dashboradAction = getOrgAction('dashboards', { icon: DashboardIcon });
    const variablesAction = getOrgAction('variables', { icon: VariableIcon });
    const cloudCompassAction: Action = getOrgAction('cloud', {
      name: 'Cloud Compass',
      keywords: 'cloud compass',
      icon: CloudInsightsIcon
    });

    return compact([
      docsAction,
      homepageAction,
      can('VIEW_DASHBOARD') && dashboradAction,
      can('VIEW_DASHBOARD') && cloudCompassAction,
      variablesAction,
      ...generateTemplatesSectionActions(navigate),
      ...generateModulesSectionActions(navigate),
      can('EDIT_ORGANIZATION_SETTINGS') && organizationSettingsBasicAction
    ]);
  }, [hasPermission, navigate]);
}
