import type { BreadcrumbSelector, BreadcrumbType } from 'components/breadcrumbs/use-breadcrumbs';
import omit from 'lodash/omit';

const projectBreadcrumbs: BreadcrumbSelector = opts => [opts.organizationName, opts.projects, opts.projectName];
const organizationBreadcrumbs: BreadcrumbSelector = opts => [opts.organizationName];

const withoutLink = (breadcrumb: BreadcrumbType) => omit(breadcrumb, 'to');

export const selectors: Record<string, BreadcrumbSelector> = {
  deploymentLogIdSelector: opts => [
    ...projectBreadcrumbs(opts),
    opts.projectEnvironments,
    opts.environmentName,
    opts.deployments,
    opts.deploymentId
  ],
  environmentIdSelector: opts => [
    ...projectBreadcrumbs(opts),
    opts.projectEnvironments,
    withoutLink(opts.environmentName)
  ],
  environmentsSelector: opts => [...projectBreadcrumbs(opts), withoutLink(opts.projectEnvironments)],
  credentialsSelector: opts => [...projectBreadcrumbs(opts), withoutLink(opts.projectCredentials)],
  templateManageSelector: opts => [...projectBreadcrumbs(opts), opts.projectTemplates, 'templates.manage.breadcrumb'],
  projectTemplatesSelector: opts => [...projectBreadcrumbs(opts), withoutLink(opts.projectTemplates)],
  projectSubProjectsSelector: opts => [...projectBreadcrumbs(opts), withoutLink(opts.projectSubProjects)],
  projectTemplateSettingsSelector: opts => [
    ...projectBreadcrumbs(opts),
    opts.projectTemplates,
    { ...opts.templateName, to: '#' },
    'navigation.settings'
  ],
  environmentRemoteStateSelector: opts => [
    ...projectBreadcrumbs(opts),
    opts.projectEnvironments,
    opts.environmentName,
    opts.states,
    opts.stateVersionId
  ],

  beforeRunTemplate: opts => [
    ...projectBreadcrumbs(opts),
    opts.projectTemplates,
    opts.templateName,
    'environment.before.deploy.run.environment'
  ],
  beforeRunEnvironment: opts => [
    ...projectBreadcrumbs(opts),
    opts.projectEnvironments,
    opts.environmentName,
    'environment.redeploy'
  ],
  editVCSEnvironment: opts => [
    ...projectBreadcrumbs(opts),
    opts.projectEnvironments,
    opts.environmentName,
    'templates.singleUse.edit'
  ],

  variablesSelector: opts => [...projectBreadcrumbs(opts), 'variables'],
  addNewVariableSetSelector: opts => [...projectBreadcrumbs(opts), 'settings.variables.add.set'],
  projectCostsSelector: opts => [...projectBreadcrumbs(opts), 'navigation.costs'],
  projectSettingsActiveMenuSelector: opts => [...projectBreadcrumbs(opts), 'navigation.settings'],
  organizationActiveMenuSelector: opts => [...organizationBreadcrumbs(opts), 'navigation.settings'],
  addNewTemplateSelector: opts => [...organizationBreadcrumbs(opts), opts.organizationTemplates, 'templates.add.new'],
  templatesTemplateIdSelector: opts => [
    ...organizationBreadcrumbs(opts),
    opts.organizationTemplates,
    opts.templateName,
    'navigation.settings'
  ],
  templatesSelector: opts => [...organizationBreadcrumbs(opts), withoutLink(opts.organizationTemplates)],
  variablesActiveMenuSelector: opts => [...organizationBreadcrumbs(opts), 'variables'],
  addNewVariableSetOrganizationSelector: opts => [...organizationBreadcrumbs(opts), 'settings.variables.add.set'],
  userPersonalSettingsSelector: () => ['navigation.user.personal-settings'],
  projectsSelector: opts => [opts.organizationName, withoutLink(opts.projects)],
  teamSelector: opts => [...organizationBreadcrumbs(opts), 'navigation.settings', opts.teams, opts.teamName],
  roleCreationSelector: opts => [...organizationBreadcrumbs(opts), 'navigation.settings', opts.roles, 'create.role'],
  rolesSelector: opts => [...organizationBreadcrumbs(opts), 'navigation.settings', opts.roles],
  createRoleSelector: opts => [...organizationBreadcrumbs(opts), 'navigation.settings', 'create.role'],
  updateRoleSelector: opts => [...organizationBreadcrumbs(opts), 'navigation.settings', 'update.role', opts.roleId],
  dashboardsSelector: opts => [...organizationBreadcrumbs(opts), withoutLink(opts.dashboards)],
  registrySelector: opts => [...organizationBreadcrumbs(opts), opts.registry, opts.registryActiveMenu],
  moduleSelector: opts => [...organizationBreadcrumbs(opts), opts.registry, opts.modules, withoutLink(opts.module)],
  providerSelector: opts => [...organizationBreadcrumbs(opts), opts.registry, opts.providers],
  moduleTestSelector: opts => [
    ...organizationBreadcrumbs(opts),
    opts.registry,
    opts.modules,
    opts.module,
    opts.moduleTest
  ],
  cloudSelector: opts => [...organizationBreadcrumbs(opts), opts.cloud, opts.cloudTab],
  cloudConfigurationSelector: opts => [
    ...organizationBreadcrumbs(opts),
    opts.cloud,
    opts.cloudSettings,
    opts.cloudConfiguration
  ],
  environmentImportSelector: opts => [
    ...projectBreadcrumbs(opts),
    opts.environmentDiscovery,
    'navigation.import.environments'
  ],
  environmentImportRerunSelector: opts => [
    ...projectBreadcrumbs(opts),
    opts.environmentDiscovery,
    'navigation.import.environments.rerun'
  ],
  homepageSelector: opts => [...organizationBreadcrumbs(opts), withoutLink(opts.homepage)]
};
