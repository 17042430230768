import React from 'react';
import { observer } from 'mobx-react';
import useStores from 'hooks/use-stores.hooks';
import withLabel from 'components/common/form-controlled/with-label.hoc';
import TextInput from 'components/common/input-components/text-input';
import { FieldRow } from 'components/common/form-components';
import { setLink } from 'components/common/link';
import { FormattedMessage } from 'react-intl';
import type { GitHostedProviderTypes } from 'types/api.types';

const LabeledTextInput = withLabel(TextInput);

export interface GitOnPremWebhookProps {
  webhookBaseUrl: string;
  webhookSecret: string;
  gitProvider: GitHostedProviderTypes;
  docsLink: string;
  secretDocsLink: string;
  isTwoRows?: boolean;
}

const GitOnPremWebhookFields: React.FunctionComponent<GitOnPremWebhookProps> = ({
  webhookBaseUrl,
  gitProvider,
  docsLink,
  secretDocsLink,
  webhookSecret,
  isTwoRows
}) => {
  const { organizationsStore } = useStores();
  const organizationId = organizationsStore.currentOrganizationId;

  const webhookUrl = `${webhookBaseUrl}?orgId=${organizationId}`;

  const webhookSecretField = (
    <LabeledTextInput
      inline
      width={isTwoRows ? 100 : 50}
      label={{ id: `templates.add.new.field.git-on-prem.webhook-secret.label`, values: { gitProvider } }}
      info={{
        id: `templates.add.new.field.git-on-prem.webhook-secret.info`,
        values: { ...setLink(secretDocsLink), gitProvider }
      }}
      data-e2e={`git-on-prem-webhook-secret-field`}
      disabled={true}
      value={webhookSecret}
    />
  );

  const webhookUrlField = (
    <LabeledTextInput
      inline
      width={isTwoRows ? 100 : 50}
      label={{ id: `templates.add.new.field.git-on-prem.webhook-url.label`, values: { gitProvider } }}
      info={{ id: `templates.add.new.field.git-on-prem.webhook-url.info`, values: { gitProvider } }}
      data-e2e={`git-on-prem-webhook-url-field`}
      disabled={true}
      value={webhookUrl}
    />
  );

  return (
    <>
      {isTwoRows ? (
        <>
          <FieldRow>{webhookUrlField}</FieldRow>
          <FieldRow>{webhookSecretField}</FieldRow>
        </>
      ) : (
        <FieldRow>
          {webhookUrlField}
          {webhookSecretField}
        </FieldRow>
      )}

      <div>
        <FormattedMessage
          data-e2e={`git-on-prem-webhooks-docs-link`}
          id={`templates.add.new.git-on-prem.app.explanation`}
          values={setLink(docsLink)}
        />
      </div>
    </>
  );
};

export default observer(GitOnPremWebhookFields);
