import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import useApiClient from 'hooks/use-api-client';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { ENVIRONMENT_IMPORT_BASE_KEY } from 'stores/rq/environment-import';

const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();

  const baseKey = [orgId, 'environment-discovery'] as const;
  const cacheKeys = {
    byProjectId: (projectId: string) => [...baseKey, 'byProjectId', { projectId }] as const
  } as const;

  return cacheKeys;
};

export const useGetEnvironmentDiscovery = (projectId?: string, enabled = true) => {
  const projectIdFromUrl = useCurrentProjectId<string>();
  const currentProjectId = projectId || projectIdFromUrl;
  const apiClient = useApiClient();
  const { byProjectId } = useCacheKeys();

  return useQuery({
    queryKey: byProjectId(currentProjectId),
    queryFn: async ({ queryKey: [, , , { projectId }] }) => {
      return !projectId ? null : apiClient.blueprints.getEnvironmentDiscovery(projectId);
    },
    enabled
  });
};

export const useUpsertEnvironmentDiscovery = (projectId?: string) => {
  const projectIdFromUrl = useCurrentProjectId<string>();
  const currentProjectId = projectId || projectIdFromUrl;
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { byProjectId } = useCacheKeys();

  return useMutation({
    mutationFn: (newEnvironmentDiscovery: BlueprintApi.EnableEnvironmentDiscovery.Request.Body) =>
      apiClient.blueprints.upsertEnvironmentDiscovery(currentProjectId, newEnvironmentDiscovery),
    onSuccess: environmentDiscovery => {
      const queryKey = byProjectId(currentProjectId);
      queryClient.setQueryData(queryKey, environmentDiscovery);
      queryClient.refetchQueries({ queryKey });
      queryClient.refetchQueries({ queryKey: ENVIRONMENT_IMPORT_BASE_KEY });
    }
  });
};

export const useDeleteEnvironmentDiscovery = (projectId?: string) => {
  const projectIdFromUrl = useCurrentProjectId<string>();
  const currentProjectId = projectId || projectIdFromUrl;
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { byProjectId } = useCacheKeys();

  return useMutation({
    mutationFn: () => apiClient.blueprints.deleteEnvironmentDiscovery(currentProjectId),
    onSuccess: () => {
      const queryKey = byProjectId(currentProjectId);
      queryClient.setQueryData(queryKey, null);
      queryClient.refetchQueries({ queryKey });
    }
  });
};
